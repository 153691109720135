<template>
  <div
    v-show="commonState.toastData.isShow"
    class="toast-container"
    :class="`toast-container--${commonState.toastData.type}`"
  >
    <component
      :is="iconMapper[commonState.toastData.type]"
      width="16"
      height="16"
      fill="white"
    />

    <Text
      type="p2"
      weight="medium"
    >
      {{ commonState.toastData.message }}
    </Text>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { TToastType } from '~/interfaces'
  import { useCommonStore } from '~/store'

  import CheckCircleSolid from '~/assets/icons/check-circle-solid.svg'
  import CrossCircleSolid from '~/assets/icons/cross-circle-solid.svg'
  import InfoSolid from '~/assets/icons/info-solid.svg'
  import WarningSolid from '~/assets/icons/warning-solid.svg'

  const iconMapper: Record<TToastType, string> = {
    success: CheckCircleSolid,
    error: CrossCircleSolid,
    warning: WarningSolid,
    info: InfoSolid,
  }

  const commonStore = useCommonStore()
  const { state: commonState } = storeToRefs(commonStore)
</script>

<style scoped lang="scss">
  .toast-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    color: $base100;
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: map-get($map: $zIndexMap, $key: toast);
    white-space: pre;
  }

  .toast-container--success {
    background: $green;
  }

  .toast-container--error {
    background: $red;
  }

  .toast-container--warning {
    background: $yellow;
  }

  .toast-container--info {
    background: $purple;
  }

  .toast-container {
    -webkit-animation: fadein 0.5s, fadeout 0.5s 3.5s;
    animation: fadein 0.5s, fadeout 0.5s 3.5s;
  }

  @-webkit-keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 16px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 16px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      top: 16px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      top: 16px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .toast-container {
      left: 18px;
      right: 18px;
    }
  }
</style>
