<template>
  <Modal
    v-if="commonState.registerModalState.isShow"
    class="registration-modal"
    @close-modal="onClickCloseModal"
  >
    <Button
      v-if="currentStep === 'emailVerification'"
      text-only
      class="back-btn"
      @click="currentStep = 'registration'"
    >
      <ArrowIcon
        width="20"
        height="20"
      />
    </Button>
    <div class="registration-modal-header">
      <component
        :is="displayStep[currentStep].icon"
        width="160"
        height="160"
      />
      <Text
        type="p1"
        weight="bold"
      >
        {{ displayStep[currentStep].title }}
      </Text>
      <Text
        type="p2"
        class="registration-caption"
      >
        {{ displayStep[currentStep].caption }}
      </Text>
    </div>

    <div v-if="currentStep === 'registration'">
      <InputText
        id="name"
        :value="values.name"
        placeholder="Nama Lengkap"
        class="registration-input"
        :error-message="nameErrorMessage"
        @input="(value) => setFieldValue('name', value)"
      />
      <InputText
        id="email"
        :value="values.email"
        placeholder="Email"
        class="registration-input"
        :error-message="emailErrorMessage"
        @input="(value) => setFieldValue('email', value)"
      />
      <InputText
        id="phone"
        :value="values.phone_number"
        placeholder="No. Handphone"
        class="registration-input"
        :error-message="phoneNumberErrorMessage"
        @input="(value) => setFieldValue('phone_number', value)"
      />
      <InputTextPassword
        label="Kata Sandi"
        :value="values.password"
        is-show-password-criteria
        @input="(value) => setFieldValue('password', value)"
        @criteria-change="(val) => (isPasswordCriteriaFulfilled = val)"
      />
    </div>
    <InputOtp
      v-else
      :disabled="isLoading"
      :value="values.otp"
      :error-message="otpErrorMessage"
      @change="handleChangeOtp"
      @complete="onVerificationClick"
    />

    <Button
      v-if="currentStep === 'registration'"
      :loading="isLoading"
      :disabled="!meta.valid || !isPasswordCriteriaFulfilled"
      @click="onSendClick"
    >
      Daftar
    </Button>
    <Button
      v-else
      text-only
      :loading="isLoading"
      @click="resendOtp"
    >
      {{ sendOtpText }}
    </Button>
  </Modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { object, string } from 'yup'

  import ArrowIcon from '~/assets/icons/arrow.svg'
  import NotRegisteredIcon from '~/assets/icons/not-registered.svg'
  import EmailVerificationIcon from '~/assets/icons/email-verification.svg'

  import {
    POST_REGISTER,
    POST_RESEND_OTP,
    POST_VALIDATE_OTP,
  } from '~/constants'
  import { useCommonStore } from '~/store'
  import {
    checkIsEmail,
    checkIsPhoneNumber,
    formatMilliseconds,
    getLatestOtpTime,
  } from '~/utils'
  import { IRegistrationForm, EOtpType } from '~/interfaces'

  type TStep = 'registration' | 'emailVerification'

  interface IStepDetail {
    icon: string
    title: string
    caption: string
  }

  const displayStep: Record<TStep, IStepDetail> = {
    registration: {
      icon: NotRegisteredIcon,
      title: 'Email Anda Belum Terdaftar',
      caption: 'Silakan melakukan pendaftaran',
    },
    emailVerification: {
      icon: EmailVerificationIcon,
      title: 'Verifikasi Email',
      caption:
        'Kami telah mengirimkan kode verifikasi ke email Anda. Masukkan kode tersebut di sini untuk melanjutkan',
    },
  }

  const commonStore = useCommonStore()
  const { state: commonState } = storeToRefs(commonStore)

  const currentStep = ref<TStep>('registration')
  const isLoading = ref(false)
  const isPasswordCriteriaFulfilled = ref(false)
  const otpErrorMessage = ref('')
  const currentTime = ref(0)

  const { values, setFieldValue, validate, meta, resetForm } =
    useForm<IRegistrationForm>({
      validationSchema: object({
        name: string().required().label('Nama Lengkap').min(4).max(255),
        email: string()
          .required()
          .label('Email')
          .test({
            name: 'email',
            test(value, ctx) {
              if (!checkIsEmail(value)) {
                return ctx.createError({
                  message: 'Email tidak valid',
                })
              }

              return true
            },
          }),
        phone_number: string()
          .required()
          .label('No. Handphone')
          .test({
            name: 'phoneNumber',
            test(value, ctx) {
              if (!checkIsPhoneNumber(value)) {
                return ctx.createError({
                  message: 'No. Handphone tidak valid',
                })
              }

              return true
            },
          }),
        password: string().required().label('Kata Sandi').min(8).max(255),
        otp: string().label('Kode verifikasi'),
      }),
      initialValues: {
        name: '',
        email: '',
        phone_number: '',
        password: '',
        otp: '',
      },
    })

  const { errorMessage: nameErrorMessage } = useField('name')
  const { errorMessage: emailErrorMessage } = useField('email')
  const { errorMessage: phoneNumberErrorMessage } = useField('phone_number')

  const sendOtpText = computed(() => {
    if (currentTime.value) {
      return formatMilliseconds(currentTime.value)
    }

    return 'Kirim Ulang'
  })

  useIntervalFn(() => {
    if (commonState.value.registerModalState.isShow) {
      const otpTime = getLatestOtpTime()

      if (otpTime) {
        const timeDiff = new Date(otpTime).getTime() - new Date().getTime()

        if (timeDiff > 0) {
          currentTime.value = timeDiff
        } else {
          currentTime.value = 0
        }
      }
    }
  }, 1000)

  const resendOtp = async () => {
    if (!currentTime.value) {
      const body = { email: values.email }
      const { error } = await api({
        path: POST_RESEND_OTP,
        options: { body },
      })

      if (!error.value) {
        setLatestOtpTime()

        commonStore.toggleToast({
          type: 'success',
          message: 'Kode OTP telah dikirim',
        })
      }
    }
  }

  const handleChangeOtp = (value: string) => {
    otpErrorMessage.value = ''
    setFieldValue('otp', value)
  }

  const onSendClick = async () => {
    isLoading.value = true

    const { valid } = await validate()

    if (valid) {
      const body = { ...values }

      const { error } = await api<{}, { statusCode: number }>({
        path: POST_REGISTER,
        options: { body },
      })

      if (!error.value) {
        currentStep.value = 'emailVerification'
      }
    }

    isLoading.value = false
  }

  const onVerificationClick = async (value: string) => {
    isLoading.value = true

    const body = {
      otp: value,
      email: values.email,
      otp_type: EOtpType.REGISTER,
    }

    const { error } = await api({
      path: POST_VALIDATE_OTP,
      options: { body },
    })

    if (!error.value) {
      resetForm()
      commonStore.toggleRegisterModal('')
      commonStore.toggleToast({
        type: 'success',
        message:
          'Verifikasi akun berhasil. Silahkan masuk menggunakan email dan password yang didaftarkan.',
      })
    }

    isLoading.value = false
  }

  const onClickCloseModal = () => {
    resetForm()
    commonStore.toggleRegisterModal('')
  }

  watch(
    () => ({ prevEmail: commonState.value.registerModalState.email }),
    ({ prevEmail }) => {
      if (prevEmail) setFieldValue('email', prevEmail)
    },
  )
</script>

<style scoped lang="scss">
  ::v-deep(.registration-modal) {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .back-btn {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .registration-modal-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      svg {
        margin: auto;
      }

      .registration-caption {
        color: $base800;
      }
    }

    .registration-input {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      input {
        width: 100%;
      }
    }

    .otp-container {
      display: flex;
      justify-content: space-between;

      .otp-input {
        input {
          width: 45px;
          text-align: center;
        }
      }
    }

    @media screen and (max-width: ($mobileLarge + 'px')) {
      width: auto;
    }
  }
</style>
