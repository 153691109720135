<template>
  <footer>
    <div class="main-footer-bg">
      <DefaultContainer class="main-footer-container">
        <div>
          <Logo class="mb-6" />
          <Text>{{ aboutText }}</Text>
        </div>
        <div class="menus-container">
          <div
            v-for="(footerMenu, idx) in footerMenus"
            :key="`footer-menu-${idx}`"
            class="menu"
          >
            <Text
              type="h6"
              weight="bold"
              class="title-margin"
            >
              {{ footerMenu.mainTitle }}
            </Text>
            <div class="submenus-container">
              <NuxtLink
                v-for="(menu, subIdx) in footerMenu.menus"
                :key="`footer-submenu-${subIdx}`"
                :to="menu.path"
                class="title-margin"
              >
                {{ menu.title }}
              </NuxtLink>
            </div>
          </div>
        </div>
        <div>
          <Text
            type="h6"
            weight="bold"
            class="mb-7"
          >
            Kontak Kami
          </Text>
          <div class="d-flex">
            <NuxtLink
              v-for="(socialMedia, idx) in socialMedias"
              :key="idx"
              :to="socialMedia.path"
              class="socmed"
            >
              <component
                :is="socialMedia.icon"
                width="20"
                height="20"
              />
            </NuxtLink>
          </div>
        </div>
      </DefaultContainer>
    </div>
    <div class="license-footer-bg">
      <DefaultContainer class="license-footer-container">
        <Text type="p3">
          {{ licenseText }}
        </Text>
      </DefaultContainer>
    </div>
  </footer>
</template>

<script setup lang="ts">
  import Logo from './Logo.vue'

  import MailIcon from '~/assets/icons/mail.svg'
  import FacebookIcon from '~/assets/icons/facebook.svg'
  import InstagramIcon from '~/assets/icons/instagram.svg'

  import { IMenu } from '~/interfaces'

  interface IFooterMenu {
    mainTitle: string
    menus: IMenu[]
  }

  interface ISocialMediaMenu {
    icon: string
    path: string
  }

  const aboutText = '24 Raffles Place #20-03, Clifford Centre Singapore 048621'
  const footerMenus: IFooterMenu[] = [
    {
      mainTitle: 'Cari Properti',
      menus: [
        { title: 'Beli', path: '/search' },
        { title: 'Sewa', path: '/search' },
        { title: 'Properti Terbaru', path: '/search' },
      ],
    },
    {
      mainTitle: 'Lainnya',
      menus: [
        { title: 'Tentang Kami', path: '/about-us' },
        { title: 'FAQ', path: '/faq' },
        { title: 'Privacy Policy', path: '/privacy-policy' },
      ],
    },
  ]
  const socialMedias: ISocialMediaMenu[] = [
    {
      icon: MailIcon,
      path: '/',
    },
    {
      icon: FacebookIcon,
      path: '/',
    },
    {
      icon: InstagramIcon,
      path: '/',
    },
  ]
  const licenseText = '© 2023 JualPabrik All Rights Reserved'
</script>

<style scoped lang="scss">
  .main-footer-bg {
    background: $blue500;
    padding: 100px 110px 120px;
  }

  .main-footer-container {
    color: $base100;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .menus-container {
      display: flex;
    }

    .menu {
      min-width: 282px;
    }

    .title-margin {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }

    .submenus-container {
      word-wrap: break-word;
      white-space: pre-wrap;

      a {
        color: white;
        display: block;
        width: fit-content;
      }
    }

    .socmed {
      display: flex;
      background-color: $orange500;
      padding: 8px;
      margin-right: 24px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .license-footer-bg {
    background: $blue600;
  }

  .license-footer-container {
    color: $base100;
    text-align: center;
    padding: 8px 0;
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .main-footer-bg {
      padding: 80px 16px;
    }

    .main-footer-container {
      flex-direction: column;
      gap: 50px;

      .menu {
        min-width: 0;
        width: 50%;
      }
    }
  }
</style>
