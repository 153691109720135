import { defineStore } from 'pinia'
import { GET_PROFILE } from '~/constants'

import { IAuthState, IProfile, IResponse } from '~/interfaces'

export const useAuthStore = defineStore('auth', () => {
  const getInitialState = (): IAuthState => ({
    profile: {
      id: '',
      photo: '',
      email: '',
      name: '',
      phone_number: '',
      status: 0,
      created_at: '',
      updated_at: '',
      company: {
        id: '',
        name: '',
        company_type: 0,
        province_id: '',
        city_id: '',
        district_id: '',
        address: '',
        company_photo: '',
        status: 0,
        created_at: '',
        updated_at: '',
        deleted_at: '',
      },
    },
  })
  const state = ref<IAuthState>(getInitialState())

  const setState = (data: IProfile) => (state.value.profile = { ...data })
  const resetState = () => (state.value = getInitialState())

  const fetchProfile = async () => {
    await api<IResponse<IProfile>>({
      isHideToast: true,
      path: GET_PROFILE,
      successCb: (val) => setState(val.data.value.data),
    })
  }

  return {
    state,
    fetchProfile,
    setState,
    resetState,
  }
})
