import { defineStore } from 'pinia'

import { IProfileState } from '~/interfaces'

export const useProfileStore = defineStore('profile', () => {
  const getInitialState = (): IProfileState => ({
    isShowSellerModal: false,
  })
  const state = ref<IProfileState>(getInitialState())

  const toggleSellerFormModal = () => {
    state.value.isShowSellerModal = !state.value.isShowSellerModal
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    toggleSellerFormModal,
    resetState,
  }
})
