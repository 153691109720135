export interface IListItem<T> {
  limit: number
  page: number
  rows: T[] | null
  sort: string
  total_pages: number
  total_rows: number
}

export interface IResponse<T> {
  data: T
}

export interface IDefaultTimestamp {
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export enum EStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
}

export interface IErrorResponse {
  message: string
  statusCode: EStatusCode
  data: {
    error: string
  }
}

export interface Data {
  error: string
}
