import { getToken } from '~/utils'

export default defineNuxtRouteMiddleware((to) => {
  const token = getToken()
  const isAuthenticatedRoute = to.fullPath.split('/')[1] === 'me'

  if (isAuthenticatedRoute && !token.value) {
    return navigateTo('/')
  }
})
