<template>
  <Navbar />
  <NuxtPage />
  <Footer />
  <LoginModal />
  <RegisterModal />
  <ResetPasswordModal />
  <Toast />
</template>

<script setup>
  import { setLocale } from 'yup'

  import Footer from '~/components/common/Footer.vue'
  import LoginModal from '~/components/common/authentication/LoginModal.vue'
  import Navbar from '~/components/common/Navbar.vue'
  import RegisterModal from '~/components/common/authentication/RegisterModal.vue'
  import ResetPasswordModal from '~/components/common/authentication/ResetPasswordModal.vue'

  import {
    useAuthStore,
    useBannerStore,
    useFavoriteStore,
    useMasterStore,
    usePropertyStore,
    useCommonStore,
  } from './store'

  const { fetchProfile } = useAuthStore()
  const { toggleToast } = useCommonStore()
  const { fetchBanner } = useBannerStore()
  const { fetchCertificates, fetchConditions, fetchProvinces } =
    useMasterStore()
  const { fetchPropertyCategories } = usePropertyStore()
  const { fetchFavorites } = useFavoriteStore()

  if (getToken().value) {
    fetchProfile()
    fetchFavorites()
  }

  await fetchBanner()
  await fetchCertificates()
  await fetchConditions()
  await fetchProvinces()
  await fetchPropertyCategories()

  onBeforeMount(() => {
    setLocale({
      string: {
        min: ({ label, min }) => `${label} minimal ${min} karakter`,
        max: ({ label, max }) => `${label} maksimal ${max} karakter`,
      },
      mixed: {
        required: ({ label }) => `${label} wajib diisi`,
      },
    })
  })

  onErrorCaptured((error) => {
    useBugsnag().notify(error)

    toggleToast({
      type: 'error',
      message:
        'Terjadi Kesalahan. Mohon refresh halaman atau hubungi Customer Service',
    })
  })
</script>

<style lang="scss">
  @use '~/assets/css/index.scss';
</style>
