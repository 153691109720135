import { MAX_OTP_TIME_MS } from '~/constants'
import {
  EPropertySellType,
  EPropertyType,
  TPropertyLocQueryKey,
} from '~/interfaces'

export interface ISetSearchHistoriesProps {
  // Search by Keyword (property name)
  keyword: string
  keywordText: string

  // Search by suggestion
  text: string
  type: EPropertySellType
  category: {
    key: EPropertyType
    label: string
  }
  area: {
    queryKey: TPropertyLocQueryKey
    value: string
    label: string
  }
}

const TOKEN_KEY = 'access_token'
const SEARCH_HISTORIES_KEY = 'search_histories'
const LATEST_OTP_KEY = 'latest_otp_time'

// Search Histories
export const getSearchHistories = (): ISetSearchHistoriesProps[] => {
  const existingSearchHistories = localStorage.getItem(SEARCH_HISTORIES_KEY)

  if (existingSearchHistories) return JSON.parse(existingSearchHistories)

  return []
}

export const setSearchHistories = (searchHistory: ISetSearchHistoriesProps) => {
  const existingSearchHistories = getSearchHistories()

  existingSearchHistories.unshift(searchHistory)

  if (existingSearchHistories.length > 5) existingSearchHistories.pop()

  localStorage.setItem(
    SEARCH_HISTORIES_KEY,
    JSON.stringify(existingSearchHistories),
  )
}

// Token
export const setToken = (token: string | null) => {
  const tokenInCookie = useCookie(TOKEN_KEY)
  tokenInCookie.value = token
}

export const getToken = () => useCookie(TOKEN_KEY)

// Latest Time OTP
export const setLatestOtpTime = () => {
  localStorage.setItem(
    LATEST_OTP_KEY,
    new Date(new Date().getTime() + MAX_OTP_TIME_MS).toISOString(),
  )
}

export const getLatestOtpTime = () => {
  return localStorage.getItem(LATEST_OTP_KEY)
}
