import { IDefaultTimestamp } from './api'
import {
  ICertificate,
  ICity,
  ICondition,
  IDistrict,
  IElectricalPower,
  IFace,
  IFacility,
  IFurnitureCondition,
  IProvince,
  IVillage,
} from './master'
import { EAgentType, EUserType } from './profile'

export type TFilterKey =
  | 'status'
  | 'searchQuery'
  | 'selectedPropertyType'
  | 'selectedPropertySellType'
  | 'selectedStatus'

export type TPropertyLocQueryKey =
  | 'province_id'
  | 'regency_id'
  | 'district_id'
  | 'village_id'

export interface IPropertyAutocompleteSuggestion {
  queryKey: TPropertyLocQueryKey
  id: string
  name: string
  detail: string
  type: 'Provinsi' | 'Area'
}

export interface IPropertyCategory extends IDefaultTimestamp {
  id: string
  slug: EPropertyType
  name: string
  description: string
  status: number
}

export enum EPropertyType {
  Factory = 'pabrik',
  Shop = 'ruko',
  Office = 'perkantoran',
  Warehouse = 'gudang',
  BusinessSpace = 'ruang-usaha',
  Apartment = 'apartement',
  Condominium = 'kondominium',
  Villa = 'villa',
  House = 'rumah',
  Land = 'tanah',
}

export interface IPropertyTypeMenu {
  key: EPropertyType | 'other'
  text: string
  img: string
  path?: string
}

export enum EPropertySellType {
  Sale = 'sale',
  Rent = 'rent',
}

export enum EPropertyModerationStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum EPropertyStatus {
  Created = 1,
  Active = 2,
  Archived = 3,
  Sold = 4,
}

export interface IPropertyFacility {
  id: string
  property_id: string
  facility_id: string
  value: string
  facility_type: IFacility['facility_type']
  facility: IFacility
}

export interface IPropertyUser extends IDefaultTimestamp {
  id: string
  email: string
  phone_number: string
  name: string
  password: string
  company_id: string
  province_id: string
  city_id: string
  district_id: string
  is_email_verified: number
  user_type: EUserType
  photo: string
  email_verification_count: number
  token_email_verification: string
  token_email_expired: string
  token_email_forgot: string
  token_email_forgot_expired: string
  email_forgot_count: number
  status: number
}

export interface IPropertyCompany extends IDefaultTimestamp {
  id: string
  name: string
  company_type: EAgentType
  project_name: string
  address: string
  province_id: string
  city_id: string
  district_id: string
  logo: string
  status: number
  user: IPropertyUser | null
}

export interface IProperty extends IDefaultTimestamp {
  id: string
  name: string
  slug: string
  description: string
  category: IPropertyCategory | null
  type: EPropertySellType
  price: number
  surface_area: number
  building_area: number
  building_floor: number
  total_share: number
  total_views: number
  year_builth: string
  end_published: string
  facilities: IPropertyFacility[]
  extra_facilities: IPropertyFacility[]
  garage: number
  garage_fits: number
  bathroom: number
  bedroom: number
  maid_bathroom: number
  maid_bedroom: number
  certificate: ICertificate | null
  electrical_power: IElectricalPower | null
  province_id: string
  nearestPlaces: object // @TODO: not yet from BE
  attachments: IPropertyAttachment[]
  video_url: string
  thumbnail: string
  location: IPropertyLocation | null
  province: IProvince | null
  city: ICity | null
  district: IDistrict | null
  village: IVillage | null
  company: IPropertyCompany
  property_condition: ICondition | null
  furniture_condition: IFurnitureCondition | null
  property_face: IFace | null
  moderation_status: EPropertyModerationStatus
  status: number
  refresh_at: string
}

export interface IPropertyCompany extends IDefaultTimestamp {}

export interface IPropertyLocation extends IDefaultTimestamp {
  id: string
  property_id: string
  latitude: string
  longitude: string
  address: string
}

export interface IPropertyAttachment extends IDefaultTimestamp {
  id: string
  property_id: string
  attachment_id: string
  tag: string
  status: number
  attachment: IAttachment
}

export interface IAttachment extends IDefaultTimestamp {
  id: string
  company_id: string
  url: string
  status: number
}

export interface IPropertyState {
  categories: IPropertyCategory[]
  list: IProperty[]
  detail: IProperty | null
}

export interface IFavoriteObj extends IDefaultTimestamp {
  id: string
  property_id: string
  property: IProperty
  user_id: string
  status: number
}

export interface IFavoriteState {
  loading: boolean
  favorites: IFavoriteObj[]
}

// @see: https://docs.mapbox.com/api/search/geocoding
export interface IMapboxReverseGeocode {
  features: {
    type: 'Feature'
    properties: object
    geometry: object
    bbox?: [number, number, number, number]
    center: [number, number]
    relevance: number
    place_name: string
    place_type: string[]
    text: string
    address: string
    context: any[]
  }[]
}
