import { EPropertySellType, ESorting, IOption } from '~/interfaces'

export const PropertySellType = {
  [EPropertySellType.Sale]: 'Jual',
  [EPropertySellType.Rent]: 'Sewa',
}

export const PropertyConditionOptions = [
  {
    id: 'allPropertyCondition',
    label: 'Semua',
    value: 'all',
  },
  {
    id: 'newPropertyCondition',
    label: 'Properti Baru',
    value: 'new',
  },
  {
    id: 'oldPropertyCondition',
    label: 'Properti Lama',
    value: 'old',
  },
]

export const PropertyCertificateOptions = [
  {
    id: 'allPropertyCertificate',
    label: 'Semua',
    value: 'all',
  },
  {
    id: 'shmPropertyCertificate',
    label: 'SHM',
    value: 'shm',
  },
  {
    id: 'hgbPropertyCertificate',
    label: 'HGB',
    value: 'hgb',
  },
  {
    id: 'useRightPropertyCertificate',
    label: 'Hak Pakai',
    value: 'useRight',
  },
  {
    id: 'loanRightPropertyCertificate',
    label: 'Hak Sewa',
    value: 'loanRight',
  },
]

export const PropertyBedroomOptions = [
  {
    id: 'allBedroom',
    label: 'Semua',
    value: '',
  },
  {
    id: 'onePropertyBedroom',
    label: '1',
    value: '1',
  },
  {
    id: 'twoPropertyBedroom',
    label: '2',
    value: '2',
  },
  {
    id: 'threePropertyBedroom',
    label: '3',
    value: '3',
  },
  {
    id: 'fourPropertyBedroom',
    label: '4',
    value: '4',
  },
]

export const PropertyBathroomOptions = [
  {
    id: 'allBathroom',
    label: 'Semua',
    value: '',
  },
  {
    id: 'onePropertyBathroom',
    label: '1',
    value: '1',
  },
  {
    id: 'twoPropertyBathroom',
    label: '2',
    value: '2',
  },
  {
    id: 'threePropertyBathroom',
    label: '3',
    value: '3',
  },
  {
    id: 'fourPropertyBathroom',
    label: '4',
    value: '4',
  },
]

export const PropertySortingOptions: IOption[] = [
  {
    label: 'Terakhir Dibuat',
    value: ESorting.CreatedAtDesc,
  },
]
