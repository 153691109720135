import { defineStore } from 'pinia'

import { ICommonState, TToastType } from '~/interfaces'

export const useCommonStore = defineStore('common', () => {
  const getInitialState = (): ICommonState => ({
    isShowLoginModal: false,
    registerModalState: {
      isShow: false,
      email: '',
    },
    resetPasswordModalState: {
      isShow: false,
      email: '',
    },
    toastData: {
      isShow: false,
      message: '',
      type: 'success',
    },
  })
  const state = ref<ICommonState>(getInitialState())

  const toggleLoginModal = () => {
    state.value.isShowLoginModal = !state.value.isShowLoginModal
  }

  const toggleRegisterModal = (email: string) => {
    state.value.registerModalState = {
      isShow: !state.value.registerModalState.isShow,
      email,
    }
  }

  const toggleResetPasswordModal = (email: string) => {
    state.value.resetPasswordModalState = {
      isShow: !state.value.resetPasswordModalState.isShow,
      email,
    }
  }

  const toggleToast = ({
    message,
    type,
  }: {
    type: TToastType
    message: string
  }) => {
    state.value.toastData = {
      isShow: true,
      message,
      type,
    }

    setTimeout(() => {
      state.value.toastData = {
        isShow: false,
        message: '',
        type: 'success',
      }
    }, 3000)
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    toggleLoginModal,
    toggleRegisterModal,
    toggleResetPasswordModal,
    toggleToast,
    resetState,
  }
})
