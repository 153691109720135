import { AsyncData, UseFetchOptions } from 'nuxt/app'
import { BASE_URL, TPath } from '~/constants'
import { EStatusCode, IErrorResponse } from '~/interfaces'
import { useCommonStore } from '~/store'
import { getToken, logout } from '~/utils'

interface IApiParams<T> {
  isHideToast?: boolean
  isExternalApi?: boolean
  params?: Record<string, string>
  path: TPath
  options?: UseFetchOptions<any>
  successCb?: (val: T) => void
}

const pathFormatting = <T>({ params, path }: IApiParams<T>): string => {
  let formattedPath = path[1]

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      formattedPath = formattedPath.replace(`:${key}`, value)
    })
  }

  return formattedPath
}

export const api = async <TResponse = {}, TError = IErrorResponse>(
  args: IApiParams<AsyncData<TResponse, TError>>,
) => {
  const formattedPath = pathFormatting<AsyncData<TResponse, TError>>(args)
  const token = getToken()

  const defaultHeaders: HeadersInit = {}

  if (token.value) defaultHeaders.Authorization = `Bearer ${token.value}`

  const response = (await useFetch(formattedPath, {
    method: args.path[0],
    baseURL: args.isExternalApi ? '' : BASE_URL,
    ...args.options,
    headers: {
      ...defaultHeaders,
      ...args.options?.headers,
    },
  })) as AsyncData<TResponse, TError>

  const { toggleToast } = useCommonStore()

  if (response.error.value && !args.isHideToast) {
    // @ts-ignore
    if (response.error.value.statusCode === EStatusCode.BadRequest) {
      toggleToast({
        type: 'error',
        // @ts-ignore
        message: response.error.value.data?.error,
      })
    } else {
      toggleToast({
        type: 'error',
        message: 'Terjadi kesalahan',
      })
    }
  }

  if (response.error.value) {
    // @ts-ignore
    if (response.error.value?.statusCode === EStatusCode.Unauthorized) {
      logout()
    }
  }

  if (!response.error.value) {
    args.successCb?.(response)
  }

  return response
}
