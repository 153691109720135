import {
  IAdsForm,
  EPropertyModerationStatus,
  IOption,
  EAdsBulkAction,
} from '~/interfaces'

export const AdsStatusTableCell: Record<
  EPropertyModerationStatus,
  { label: string; color: string }
> = {
  pending: {
    label: 'Menunggu Persetujuan',
    color: '#FDA500',
  },
  approved: {
    label: 'Ditayangkan',
    color: '#12BF57',
  },
  rejected: {
    label: 'Ditolak',
    color: '#EC1313',
  },
}

export const PropertyStatusOptions: IOption[] = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Menunggu Persetujuan',
    value: EPropertyModerationStatus.Pending,
  },
  {
    label: 'Ditayangkan',
    value: EPropertyModerationStatus.Approved,
  },
  {
    label: 'Ditolak',
    value: EPropertyModerationStatus.Rejected,
  },
]

export const RoomTypes: {
  key: keyof IAdsForm['listingDetail']['roomQuantity']
  label: string
}[] = [
  {
    key: 'bedroom',
    label: 'Kamar Tidur',
  },
  {
    key: 'bathroom',
    label: 'Kamar Mandi',
  },
  {
    key: 'maidBedroom',
    label: 'Kamar Tidur Pembantu',
  },
  {
    key: 'maidBathroom',
    label: 'Kamar Mandi Pembantu',
  },
  {
    key: 'garage',
    label: 'Garasi (Jumlah Mobil)',
  },
  {
    key: 'carport',
    label: 'Carport',
  },
  {
    key: 'floor',
    label: 'Jumlah Lantai',
  },
]

export const MediaTags = [
  'Tampak Depan',
  'Tampak Samping',
  'Ruang Tamu',
  'Ruang Inti',
  'Ruang Keluarga',
  'Ruang Makan',
  'Kamar Mandi',
  'Dapur',
  'Garasi/Carport',
  'Kolam Renang',
  'Foto Lainnya',
]

export const AdsActionSuccessMessage: Record<EAdsBulkAction, string> = {
  [EAdsBulkAction.Refresh]: 'Iklan berhasil disegarkan',
  [EAdsBulkAction.Delete]: 'Iklan berhasil dihapus',
  [EAdsBulkAction.Archived]: 'Iklan berhasil diarsipkan',
  [EAdsBulkAction.Sold]: 'Iklan berhasil diset terjual',
}
