<template>
  <InputText
    :id="id || 'password'"
    :value="value"
    :label="label"
    :type="inputType"
    :error-message="errorMessage"
    @input="onInput"
  >
    <template #rightSideAction>
      <Button
        text-only
        class="d-block"
      >
        <component
          :is="inputType === 'password' ? EyeShowIcon : EyeHideIcon"
          width="16"
          height="16"
          class="d-block"
          @click="toggleShowPassword"
        />
      </Button>
    </template>

    <template
      v-if="isShowPasswordCriteria"
      #helperText
    >
      <div
        v-for="(criteria, idx) in passwordCriterias"
        :key="idx"
        class="helper-text"
      >
        <CheckCircleSolidIcon
          width="12"
          height="12"
          :fill="criteria.isFulfilled ? '#12BF57' : '#BBBBBB'"
        />
        <Text type="p4">
          {{ criteria.text }}
        </Text>
      </div>
    </template>
  </InputText>
</template>

<script setup lang="ts">
  import CheckCircleSolidIcon from '~/assets/icons/check-circle-solid.svg'
  import EyeShowIcon from '~/assets/icons/eye-show.svg'
  import EyeHideIcon from '~/assets/icons/eye-hide.svg'

  interface IInputTextPasswordProps {
    id?: string
    label: string
    value: string
    errorMessage?: string
    isShowPasswordCriteria?: boolean
  }

  interface IInputTextPasswordEmits {
    (e: 'input', value: string): void
    (e: 'criteriaChange', value: boolean): void
  }

  const props = defineProps<IInputTextPasswordProps>()
  const emits = defineEmits<IInputTextPasswordEmits>()

  const inputType = ref('password')
  const passwordCriterias = computed(() => {
    const isLengthFulfilled = props.value.length >= 8
    const isCapitalFulfilled = /(.*[A-Z].*)/.test(props.value)
    const isDigitFulfilled = /(.*\d.*)/.test(props.value)
    const isSpecialCharFulfilled = /(.*\W.*)/.test(props.value)

    emits(
      'criteriaChange',
      isLengthFulfilled &&
        isCapitalFulfilled &&
        isDigitFulfilled &&
        isSpecialCharFulfilled,
    )

    return [
      {
        text: 'Minimal 8 karakter',
        isFulfilled: isLengthFulfilled,
      },
      {
        text: 'Mengandung huruf kapital',
        isFulfilled: isCapitalFulfilled,
      },
      {
        text: 'Mengandung karakter angka',
        isFulfilled: isDigitFulfilled,
      },
      {
        text: 'Mengandung karakter khusus',
        isFulfilled: isSpecialCharFulfilled,
      },
    ]
  })

  const onInput = (value: string) => {
    emits('input', value)
  }

  const toggleShowPassword = () => {
    if (inputType.value === 'password') {
      inputType.value = 'text'
    } else {
      inputType.value = 'password'
    }
  }
</script>

<style scoped lang="scss">
  .helper-text {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $base800;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
</style>
