import { number } from 'yup'
import { IDefaultTimestamp } from './api'
import { EAgentType } from './profile'

export interface ILoginForm {
  email: string
  password: string
}

export interface IRegistrationForm {
  name: string
  email: string
  password: string
  phone_number: string
  otp: string
}

export interface ILoginResponse {
  access_token: string
  refresh_token: string
}

export interface IProfileCompany extends IDefaultTimestamp {
  id: string
  name: string
  company_type: number
  // @TODO: check again when BE provide
  project?: string
  province_id: string
  city_id: string
  district_id: string
  address: string
  logo: string
  status: number
}

export interface IProfile extends Omit<IDefaultTimestamp, 'deleted_at'> {
  id: string
  photo: string
  email: string
  name: string
  phone_number: string
  status: number
  company: IProfileCompany
}

export interface IAuthState {
  profile: IProfile
}

export enum EOtpType {
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
}
