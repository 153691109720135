import { IOption } from './common'

export enum EMyAdsTab {
  Ads = 'ads',
  Sold = 'soldAds',
  Archived = 'archivedAds',
}

export interface INearestPlace {
  name: string
  category: string
  time: string
}

export interface IAdsForm {
  listingType: {
    adsType: string
    propertyType: string
  }
  listingLocation: {
    province: IOption
    city: IOption
    district: IOption
    village: IOption
    address: string
    lat: string
    long: string
    placeName: string
    nearestPlaces: INearestPlace[]
  }
  listingDescription: {
    listingTitle: string
    price: string
    description: string
  }
  listingDetail: {
    certificate: string
    condition: string
    furniture: string
    landArea: string
    buildingArea: string
    builtAt: string
    facing: IOption
    electric: IOption
    roomQuantity: {
      bedroom: number
      maidBedroom: number
      garage: number
      floor: number
      bathroom: number
      maidBathroom: number
      carport: number
    }
    facilities: IOption[]
    extraFacilities: IOption[]
  }
  listingMedia: {
    attachments: {
      id?: string // For edit ads
      attachmentId: string
      tag: string
      imageUrl: string
    }[]
    deletedAttachmentIds?: string[] // For edit ads
    videoUrl: string
    refresh: boolean
  }
}

export interface IAdsState {
  currentTab: EMyAdsTab
}

export enum EAdsBulkAction {
  Refresh = 'refresh',
  Delete = 'delete',
  Archived = 'archived',
  Sold = 'sold',
}
