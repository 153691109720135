import { defineStore } from 'pinia'
import {
  GET_CERTIFICATES,
  GET_CITIES,
  GET_CONDITIONS,
  GET_DISTRICTS,
  GET_ELECTRICAL_POWERS,
  GET_FACES,
  GET_FACILITIES,
  GET_FURNITURE_CONDITIONS,
  GET_PROVINCES,
  GET_VILLAGES,
} from '~/constants'

import {
  ICertificate,
  ICity,
  ICondition,
  IDistrict,
  IElectricalPower,
  IFace,
  IFacility,
  IFurnitureCondition,
  IListItem,
  IMasterState,
  IProvince,
  IVillage,
} from '~/interfaces'

export const useMasterStore = defineStore('master', () => {
  const getInitialState = (): IMasterState => ({
    provinces: [],
    cities: [],
    districts: [],
    villages: [],
    conditions: [],
    certificates: [],
    furnitureConditions: [],
    faces: [],
    electricalPowers: [],
    facilities: [],
  })
  const state = ref<IMasterState>(getInitialState())

  const setProvinces = (value: IProvince[]) => (state.value.provinces = value)
  const setCities = (value: ICity[]) => (state.value.cities = value)
  const setDistricts = (value: IDistrict[]) => (state.value.districts = value)
  const setVillages = (value: IVillage[]) => (state.value.villages = value)
  const setConditions = (value: ICondition[]) =>
    (state.value.conditions = value)
  const setCertificates = (value: ICertificate[]) =>
    (state.value.certificates = value)
  const setFurnitureConditions = (value: IFurnitureCondition[]) =>
    (state.value.furnitureConditions = value)
  const setFaces = (value: IFace[]) => (state.value.faces = value)
  const setElectricalPowers = (value: IElectricalPower[]) =>
    (state.value.electricalPowers = value)
  const setFacilities = (value: IFacility[]) => (state.value.facilities = value)

  const ProvinceOptions = computed(() =>
    state.value.provinces.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const CityOptions = computed(() =>
    state.value.cities.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const DistrictOptions = computed(() =>
    state.value.districts.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const VillageOptions = computed(() =>
    state.value.villages.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const FaceOptions = computed(() =>
    state.value.faces.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const ElectricalPowerOptions = computed(() =>
    state.value.electricalPowers.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  )

  const HouseFacilityOptions = computed(() =>
    state.value.facilities.filter((item) => item.facility_type === 'area-home'),
  )

  const HousingFacilityOptions = computed(() =>
    state.value.facilities.filter((item) => item.facility_type === 'housing'),
  )

  const fetchProvinces = async () => {
    await api<IListItem<IProvince>>({
      path: GET_PROVINCES,
      successCb: (response) => setProvinces(response.data.value.rows || []),
    })
  }

  const fetchCities = async (query?: { province_id?: string }) => {
    await api<IListItem<ICity>>({
      path: GET_CITIES,
      options: { query },
      successCb: (response) => setCities(response.data.value.rows || []),
    })
  }

  const fetchDistricts = async (query?: { regency_id?: string }) => {
    await api<IListItem<IDistrict>>({
      path: GET_DISTRICTS,
      options: { query },
      successCb: (response) => setDistricts(response.data.value.rows || []),
    })
  }

  const fetchVillages = async (query?: { district_id?: string }) => {
    await api<IListItem<IVillage>>({
      path: GET_VILLAGES,
      options: { query },
      successCb: (response) => setVillages(response.data.value.rows || []),
    })
  }

  const fetchConditions = async () => {
    await api<IListItem<ICondition>>({
      path: GET_CONDITIONS,
      successCb: (response) => setConditions(response.data.value.rows || []),
    })
  }

  const fetchCertificates = async () => {
    await api<IListItem<ICertificate>>({
      path: GET_CERTIFICATES,
      successCb: (response) => setCertificates(response.data.value.rows || []),
    })
  }

  const fetchFurnitureConditions = async () => {
    await api<IListItem<IFurnitureCondition>>({
      path: GET_FURNITURE_CONDITIONS,
      successCb: (response) =>
        setFurnitureConditions(response.data.value.rows || []),
    })
  }

  const fetchFaces = async () => {
    await api<IListItem<IFace>>({
      path: GET_FACES,
      successCb: (response) => setFaces(response.data.value.rows || []),
    })
  }

  const fetchElectricalPowers = async () => {
    await api<IListItem<IElectricalPower>>({
      path: GET_ELECTRICAL_POWERS,
      successCb: (response) =>
        setElectricalPowers(response.data.value.rows || []),
    })
  }

  const fetchFacilities = async () => {
    await api<IListItem<IFacility>>({
      path: GET_FACILITIES,
      successCb: (response) => setFacilities(response.data.value.rows || []),
    })
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    ProvinceOptions,
    CityOptions,
    DistrictOptions,
    VillageOptions,
    FaceOptions,
    ElectricalPowerOptions,
    HouseFacilityOptions,
    HousingFacilityOptions,
    fetchProvinces,
    fetchCities,
    fetchDistricts,
    fetchVillages,
    fetchConditions,
    fetchCertificates,
    fetchFurnitureConditions,
    fetchFaces,
    fetchElectricalPowers,
    fetchFacilities,
    setProvinces,
    setCities,
    setDistricts,
    setVillages,
    setConditions,
    setCertificates,
    resetState,
  }
})
