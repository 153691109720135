import { EPropertyType, IPropertyTypeMenu } from '~/interfaces'

import ApartmentIcon from '~/assets/icons/PropertyType/apartment.svg'
import BusinessSpaceIcon from '~/assets/icons/PropertyType/business-space.svg'
import CondominiumIcon from '~/assets/icons/PropertyType/condominium.svg'
import FactoryIcon from '~/assets/icons/PropertyType/factory.svg'
import HouseIcon from '~/assets/icons/PropertyType/house.svg'
import LandIcon from '~/assets/icons/PropertyType/land.svg'
import OfficeIcon from '~/assets/icons/PropertyType/office.svg'
import OthersIcon from '~/assets/icons/PropertyType/others.svg'
import ShopIcon from '~/assets/icons/PropertyType/shop.svg'
import VillaIcon from '~/assets/icons/PropertyType/villa.svg'
import WarehouseIcon from '~/assets/icons/PropertyType/warehouse.svg'
import { MapboxComponentOptions } from 'nuxt-mapbox'

export const PropertyTypesLabel = {
  [EPropertyType.Factory]: 'Pabrik',
  [EPropertyType.Shop]: 'Ruko',
  [EPropertyType.Office]: 'Perkantoran',
  [EPropertyType.Warehouse]: 'Gudang',
  [EPropertyType.BusinessSpace]: 'Ruang Usaha',
  [EPropertyType.Apartment]: 'Apartemen',
  [EPropertyType.Condominium]: 'Kondominium',
  [EPropertyType.Villa]: 'Villa',
  [EPropertyType.House]: 'Rumah',
  [EPropertyType.Land]: 'Tanah',
}

export const PropertyTypesIcon = {
  [EPropertyType.Factory]: FactoryIcon,
  [EPropertyType.Shop]: ShopIcon,
  [EPropertyType.Office]: OfficeIcon,
  [EPropertyType.Warehouse]: WarehouseIcon,
  [EPropertyType.BusinessSpace]: BusinessSpaceIcon,
  [EPropertyType.Apartment]: ApartmentIcon,
  [EPropertyType.Condominium]: CondominiumIcon,
  [EPropertyType.Villa]: VillaIcon,
  [EPropertyType.House]: HouseIcon,
  [EPropertyType.Land]: LandIcon,
}

export const PropertyTypesMenuLite: IPropertyTypeMenu[] = [
  {
    key: EPropertyType.Factory,
    text: PropertyTypesLabel[EPropertyType.Factory],
    img: PropertyTypesIcon[EPropertyType.Factory],
    path: `/search?type=${EPropertyType.Factory}`,
  },
  {
    key: EPropertyType.Shop,
    text: PropertyTypesLabel[EPropertyType.Shop],
    img: PropertyTypesIcon[EPropertyType.Shop],
    path: `/search?type=${EPropertyType.Shop}`,
  },
  {
    key: EPropertyType.Office,
    text: PropertyTypesLabel[EPropertyType.Office],
    img: PropertyTypesIcon[EPropertyType.Office],
    path: `/search?type=${EPropertyType.Office}`,
  },
  {
    key: EPropertyType.Warehouse,
    text: PropertyTypesLabel[EPropertyType.Warehouse],
    img: PropertyTypesIcon[EPropertyType.Warehouse],
    path: `/search?type=${EPropertyType.Warehouse}`,
  },
  {
    key: EPropertyType.BusinessSpace,
    text: PropertyTypesLabel[EPropertyType.BusinessSpace],
    img: PropertyTypesIcon[EPropertyType.BusinessSpace],
    path: `/search?type=${EPropertyType.BusinessSpace}`,
  },
  {
    key: 'other',
    text: 'Lainnya',
    img: OthersIcon,
  },
]

export const PropertyTypesMenuFull: IPropertyTypeMenu[] = [
  ...PropertyTypesMenuLite.slice(0, 5),
  {
    key: EPropertyType.Apartment,
    text: PropertyTypesLabel[EPropertyType.Apartment],
    img: PropertyTypesIcon[EPropertyType.Apartment],
    path: `/search?type=${EPropertyType.Apartment}`,
  },
  {
    key: EPropertyType.Condominium,
    text: PropertyTypesLabel[EPropertyType.Condominium],
    img: PropertyTypesIcon[EPropertyType.Condominium],
    path: `/search?type=${EPropertyType.Condominium}`,
  },
  {
    key: EPropertyType.Villa,
    text: PropertyTypesLabel[EPropertyType.Villa],
    img: PropertyTypesIcon[EPropertyType.Villa],
    path: `/search?type=${EPropertyType.Villa}`,
  },
  {
    key: EPropertyType.House,
    text: PropertyTypesLabel[EPropertyType.House],
    img: PropertyTypesIcon[EPropertyType.House],
    path: `/search?type=${EPropertyType.House}`,
  },
  {
    key: EPropertyType.Land,
    text: PropertyTypesLabel[EPropertyType.Land],
    img: PropertyTypesIcon[EPropertyType.Land],
    path: `/search?type=${EPropertyType.Land}`,
  },
]

// Config same with dashboard
export const MapboxConfiguration: MapboxComponentOptions = {
  style: 'mapbox://styles/mapbox/streets-v11',
  center: [103.851959, 1.29027],
  zoom: 10,
}
