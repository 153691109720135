<template>
  <nav
    class="navbar-bg"
    :class="isLightTheme && 'navbar-bg--light'"
  >
    <DefaultContainer
      class="navbar-container"
      :class="isLightTheme && 'navbar-container--light'"
    >
      <Logo
        class="navbar-logo cursor-pointer"
        :is-text-orange="isLightTheme"
        @click="() => onClickMenuMobile(logoMenu)"
      />
      <div class="menu-container">
        <NuxtLink
          v-for="(menu, idx) in menus"
          :key="idx"
          :to="menu.path"
          :class="menu.mobileOnly && 'menu--mobile'"
        >
          {{ menu.title }}
        </NuxtLink>

        <!-- For Lainnya Menu in Desktop -->
        <div
          class="menu--desktop"
          @click="() => (isShowOtherMenuList = !isShowOtherMenuList)"
        >
          Lainnya

          <ChevronRight
            width="24"
            height="24"
            :transform="!isShowOtherMenuList ? 'rotate(90)' : 'rotate(-90)'"
            :fill="isLightTheme ? '#333333' : 'white'"
          />

          <div
            v-show="isShowOtherMenuList"
            class="expandable-menu-div"
          >
            <NuxtLink
              v-for="(menu, idx) in otherMenus"
              :key="idx"
              :to="menu.path"
            >
              {{ menu.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        v-if="!isAuthenticated"
        class="actions"
      >
        <Button
          :is-borderless="!isLightTheme"
          type="secondary"
          class="ads-btn mr-3"
          @click="onClickLogin"
        >
          <PlusIcon
            width="14"
            height="14"
          />
          Pasang Iklan
        </Button>
        <Button @click="onClickLogin"> Masuk </Button>
      </div>
      <div
        v-else
        class="profile-actions"
        @click="() => (isShowProfileMenuList = !isShowProfileMenuList)"
      >
        <div
          class="avatar"
          :style="authState.profile.photo ? 'padding: 0' : 'padding: 8px'"
        >
          <img
            v-if="authState.profile.photo"
            :src="authState.profile.photo"
            style="width: 32px; height: 32px; border-radius: 50%"
          />
          <ProfileSolidIcon
            v-else
            width="16"
            height="16"
            fill="#68899B"
          />
        </div>
        <Text
          type="p2"
          weight="bold"
        >
          {{ authState.profile.name }}
        </Text>
        <ChevronRight
          width="24"
          height="24"
          :transform="!isShowProfileMenuList ? 'rotate(90)' : 'rotate(-90)'"
          :fill="isLightTheme ? '#333333' : 'white'"
        />

        <div
          v-show="isShowProfileMenuList"
          class="expandable-menu-div"
        >
          <NuxtLink
            v-for="(menu, idx) in profileMenus"
            :key="idx"
            :class="menu.customClass"
            @click="() => onClickProfileMenu(menu)"
          >
            {{ menu.title }}
          </NuxtLink>
        </div>
      </div>

      <div class="navbar-menu-btn--mobile">
        <Button
          text-only
          @click="onClickShowMenusMobile"
        >
          <component
            :is="isShowMobileMenus ? CrossIcon : BurgerIcon"
            width="24"
            height="24"
            :stroke="isLightTheme ? '#333333' : 'white'"
          />
        </Button>

        <Button
          text-only
          @click="onClickShowMenusMobileProfile"
        >
          <div
            v-if="isAuthenticated"
            class="avatar"
            :class="!isLightTheme && 'avatar--dark'"
            :style="authState.profile.photo ? 'padding: 0' : 'padding: 8px'"
          >
            <img
              v-if="authState.profile.photo"
              :src="authState.profile.photo"
              style="width: 32px; height: 32px; border-radius: 50%"
            />
            <ProfileSolidIcon
              v-else
              width="16"
              height="16"
              :fill="isLightTheme ? '#68899B' : 'white'"
            />
          </div>
        </Button>
      </div>
    </DefaultContainer>

    <div
      v-if="isShowMobileMenus || isShowMobileMenusProfile"
      class="navbar--mobile"
      :class="isLightTheme && 'navbar--mobile-light'"
    >
      <div class="menu-container">
        <!-- If show mobile menus, show non profile. Show profile menus otherwise. -->
        <NuxtLink
          v-for="(menu, idx) in menus"
          v-show="
            isShowMobileMenus ? !menu.authenticatedOnly : menu.authenticatedOnly
          "
          :key="idx"
          :class="[menu.mobileOnly && 'menu--mobile']"
          @click.prevent="() => onClickMenuMobile(menu)"
        >
          {{ menu.title }}
        </NuxtLink>
      </div>

      <div
        v-if="!isAuthenticated"
        class="actions"
      >
        <Button
          :is-borderless="!isLightTheme"
          type="secondary"
          class="ads-btn mr-3"
          @click="onClickLogin"
        >
          <PlusIcon
            width="14"
            height="14"
          />
          Pasang Iklan
        </Button>
        <Button @click="onClickLogin"> Masuk </Button>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import Logo from './Logo.vue'

  import BurgerIcon from '~/assets/icons/burger.svg'
  import ChevronRight from '~/assets/icons/chevron-right.svg'
  import CrossIcon from '~/assets/icons/cross.svg'
  import PlusIcon from '~/assets/icons/plus.svg'
  import ProfileSolidIcon from '~/assets/icons/UserMenu/profile-solid.svg'

  import { useAuthStore, useCommonStore } from '~/store'
  import { logout } from '~/utils'
  import { IMenu } from '~/interfaces'

  interface INavbarMenu extends IMenu {
    customClass?: string
    desktopOnly?: boolean
    mobileOnly?: boolean
    authenticatedOnly?: boolean
    onClick?: () => void
  }

  const logoMenu: INavbarMenu = { title: '', path: '/' }

  const menus: INavbarMenu[] = [
    { title: 'Beli', path: '/search' },
    { title: 'Sewa', path: '/search' },
    { title: 'Properti Terbaru', path: '/search' },
    { title: 'Tentang Kami', path: '/about-us', mobileOnly: true },
    { title: 'FAQ', path: '/faq', mobileOnly: true },
    { title: 'Privacy Policy', path: '/privacy-policy', mobileOnly: true },
    {
      title: 'Profil',
      path: '/me/profile',
      mobileOnly: true,
      authenticatedOnly: true,
    },
    {
      title: 'Iklan Saya',
      path: '/me/ads',
      mobileOnly: true,
      authenticatedOnly: true,
    },
    {
      title: 'Riwayat Pencarian',
      path: '/me/history',
      mobileOnly: true,
      authenticatedOnly: true,
    },
    {
      title: 'Favorit',
      path: '/me/favorite',
      mobileOnly: true,
      authenticatedOnly: true,
    },
    {
      title: 'Logout',
      path: '',
      customClass: 'logout-text',
      mobileOnly: true,
      authenticatedOnly: true,
      onClick: logout,
    },
  ]

  const otherMenus: INavbarMenu[] = [
    { title: 'Tentang Kami', path: '/about-us' },
    { title: 'FAQ', path: '/faq' },
    { title: 'Privacy Policy', path: '/privacy-policy' },
  ]

  const profileMenus: INavbarMenu[] = [
    { title: 'Profil', path: '/me/profile' },
    { title: 'Iklan Saya', path: '/me/ads' },
    { title: 'Riwayat Pencarian', path: '/me/history' },
    { title: 'Favorit', path: '/me/favorite' },
    {
      title: 'Logout',
      path: '',
      customClass: 'logout-text',
      onClick: logout,
    },
  ]

  const route = useRoute()
  const { push } = useRouter()
  const { y: scrollY } = useWindowScroll()

  const authStore = useAuthStore()
  const { toggleLoginModal } = useCommonStore()

  const { state: authState } = storeToRefs(authStore)

  const isShowOtherMenuList = ref(false)
  const isShowProfileMenuList = ref(false)
  const isShowMobileMenus = ref(false)
  const isShowMobileMenusProfile = ref(false)
  const isDesktopScreen = ref(true)

  const isAuthenticated = computed(() => !!authState.value.profile.id)

  const isLightTheme = computed(() => {
    let exceedMaxY = false

    if (isDesktopScreen.value) {
      exceedMaxY = scrollY.value > 675
    } else {
      exceedMaxY = scrollY.value > 535
    }

    return route.path !== '/' || exceedMaxY
  })

  onMounted(() => {
    isDesktopScreen.value = window.screen.width > 768
  })

  const onClickShowMenusMobile = () => {
    isShowMobileMenus.value = !isShowMobileMenus.value
    document.body.style.overflow = isShowMobileMenus.value ? 'hidden' : 'auto'
  }

  const onClickShowMenusMobileProfile = () => {
    isShowMobileMenusProfile.value = !isShowMobileMenusProfile.value
    document.body.style.overflow = isShowMobileMenusProfile.value
      ? 'hidden'
      : 'auto'
  }

  const onClickMenuMobile = (menu: INavbarMenu) => {
    isShowMobileMenus.value = false
    isShowMobileMenusProfile.value = false

    document.body.style.overflow = 'auto'

    if (menu.path) push(menu.path)
    else menu.onClick?.()
  }

  const onClickProfileMenu = (menu: INavbarMenu) => {
    if (menu.path) push(menu.path)
    else menu.onClick?.()
  }

  const onClickLogin = () => {
    isShowMobileMenus.value && onClickShowMenusMobile()
    isShowMobileMenusProfile.value && onClickShowMenusMobileProfile()

    toggleLoginModal()
  }
</script>

<style scoped lang="scss">
  .navbar-bg {
    background: linear-gradient(252.63deg, #033a59 -0.9%, #0e3043 100%);
    position: fixed;
    width: 100vw;
    z-index: map-get($map: $zIndexMap, $key: navbar);
    padding: 16px 110px;
  }

  .navbar-bg--light {
    background: $base100;
    border-bottom: 1px solid $base500;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: 700;

    .avatar {
      display: flex;
      align-items: center;
      background: $base200;
      border-radius: 50%;
    }

    .avatar--dark {
      background: transparent;
    }

    .expandable-menu-div {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 52px;
      background: $base100;
      border: 1px solid $base400;

      a {
        padding: 10px 24px;
        color: $base900 !important;
        white-space: nowrap;
        min-width: 180px;

        &:hover {
          opacity: 0.5;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $base400;
        }
      }

      .logout-text {
        color: $red !important;
      }
    }

    .navbar-logo {
      width: 238px;
    }

    .menu-container {
      display: flex;
      gap: 42px;

      a {
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .menu--desktop {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .menu--mobile {
        display: none;
      }
    }

    .actions {
      display: flex;
      flex-grow: 0;

      button {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .profile-actions {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-grow: 0;
      gap: 8px;
      position: relative;
    }
  }

  .navbar-container--light {
    color: $base900;

    .menu-container {
      a {
        color: $base900;
      }
    }
  }

  @media screen and (min-width: ($smallDesktop + 1 + 'px')) {
    .navbar-container {
      .navbar-menu-btn--mobile {
        display: none;
      }
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .navbar-bg {
      padding: 0;
    }

    .navbar-container {
      padding: 14px 16px;

      .navbar-menu-btn--mobile {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .menu-container,
      .actions,
      .profile-actions {
        display: none;
      }
    }

    .navbar--mobile {
      padding: 24px;
      width: 100vw;
      height: 100vh;
      background: $blue500;
      overflow: auto;

      .menu-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 42px;
        margin-bottom: 40px;
        padding: 40px 0;

        a {
          color: white;
        }

        .menu--desktop {
          display: none;
        }

        .menu--mobile {
          display: block;
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;
        }
      }
    }

    .navbar--mobile-light {
      background: $base100;

      .menu-container {
        a {
          color: $base900;
        }
      }
    }
  }
</style>
