interface IFormatAddressProps {
  isUseTextAddress?: boolean
  textAddress?: string
  districtName?: string
  cityName?: string
  provinceName?: string
}

export const truncateText = (text: string, targetLen: number) => {
  if (text.length > targetLen) {
    return `${text.slice(0, targetLen - 3)}...`
  }

  return text
}

export const formatNumber = (val: number, isCurency?: boolean) => {
  return new Intl.NumberFormat('id-ID', {
    style: isCurency ? 'currency' : 'decimal',
    currency: 'IDR',
    maximumFractionDigits: isCurency ? 0 : 2,
  }).format(val)
}

export const formatAddress = ({
  isUseTextAddress,
  textAddress,
  districtName,
  cityName,
  provinceName,
}: IFormatAddressProps) => {
  const address = []

  if (isUseTextAddress) {
    if (textAddress) address.push(textAddress)
  }

  if (districtName) {
    address.push(districtName)
  }

  if (cityName) {
    address.push(cityName)
  }

  if (provinceName) {
    address.push(provinceName)
  }

  return address.join(', ') || '-'
}

export const checkIsEmail = (val: string) => {
  const emailRegex = /^[^\\\s@]+@[^\\\s@]+.[^\\\s@]+$/

  return emailRegex.test(val)
}

export const checkIsPhoneNumber = (val: string) => {
  const phoneRegex = /^(\+?62|0)[2-9]{1}[0-9]{7,14}$/

  return phoneRegex.test(val)
}

export const formatMilliseconds = (ms: number) => {
  const minutes = Math.floor(ms / 60000)
  const seconds = +((ms % 60000) / 1000).toFixed(0)

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const checkIsExternalUrl = (url: string) => {
  const urlRegex = /^(http|https|www).*/

  return urlRegex.test(url)
}
