import { EAgentType } from '~/interfaces'

export const agentOptions = [
  {
    id: EAgentType.CompanyAgent,
    label: 'Agen Perusahaan',
  },
  {
    id: EAgentType.DeveloperAgent,
    label: 'Developer',
  },
]
