import { defineStore } from 'pinia'

import { EMyAdsTab, IAdsState } from '~/interfaces'

export const useAdsStore = defineStore('ads', () => {
  const getInitialState = (): IAdsState => ({
    currentTab: EMyAdsTab.Ads,
  })
  const state = ref<IAdsState>(getInitialState())

  const setCurrentTab = (tab: EMyAdsTab) => (state.value.currentTab = tab)

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    setCurrentTab,
    resetState,
  }
})
