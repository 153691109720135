<template>
  <div class="modal">
    <div class="modal-overlay" />
    <div
      class="modal-container"
      :class="$attrs.class"
    >
      <slot></slot>
      <Button
        text-only
        class="close-icon"
        @click="onClickClose"
      >
        <CrossIcon
          width="20"
          height="20"
        />
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import CrossIcon from '~/assets/icons/cross.svg'

  interface IModalEmits {
    (e: 'closeModal'): void
  }

  const emits = defineEmits<IModalEmits>()
  const onClickClose = () => emits('closeModal')
</script>

<style scoped lang="scss">
  .modal {
    position: fixed;
    z-index: map-get($map: $zIndexMap, $key: modal);

    .modal-overlay {
      position: fixed;
      background: $black;
      opacity: 0.5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .modal-container {
      position: fixed;
      padding: 24px;
      background: $base100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .close-icon {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  @media screen and (max-width: ($mobileLarge + 'px')) {
    .modal {
      .modal-container {
        left: 24px;
        right: 24px;
        transform: translate(0, -50%);
      }
    }
  }
</style>
