import { defineStore } from 'pinia'
import {
  GET_MY_FAVORITES,
  ADD_MY_FAVORITES,
  REMOVE_MY_FAVORITES,
} from '~/constants'
import { getToken } from '~/utils'
import { useCommonStore } from './common'

import { IFavoriteObj, IFavoriteState, IResponse } from '~/interfaces'

interface ITogglePropertyFavorite {
  propertyId?: string
}

export const useFavoriteStore = defineStore('favorite', () => {
  const commonStore = useCommonStore()

  const getInitialState = (): IFavoriteState => ({
    loading: false,
    favorites: [],
  })
  const state = ref<IFavoriteState>(getInitialState())

  const setLoading = (value: boolean) => (state.value.loading = value)
  const setFavorites = (value: IFavoriteObj[]) =>
    (state.value.favorites = value)

  const fetchFavorites = async () => {
    await api<IResponse<{ rows: IFavoriteObj[] }>>({
      path: GET_MY_FAVORITES,
      successCb: (response) =>
        setFavorites(response.data.value.data.rows || []),
    })
  }

  const getPropertyFavoriteObj = (propertyId: string) => {
    return state.value.favorites.find((item) => item.property_id === propertyId)
  }

  const isPropertyFaved = (propertyId: string) => {
    return !!getPropertyFavoriteObj(propertyId)
  }

  const togglePropertyFavorite = async ({
    propertyId,
  }: ITogglePropertyFavorite) => {
    if (getToken().value) {
      setLoading(true)

      let favoriteId = ''
      const propertyFavStatus = isPropertyFaved(propertyId || '')

      if (propertyFavStatus) {
        favoriteId = getPropertyFavoriteObj(propertyId || '')?.id || ''
      }

      const response = await api({
        path: propertyFavStatus ? REMOVE_MY_FAVORITES : ADD_MY_FAVORITES,
        params: propertyFavStatus ? { id: favoriteId || '' } : {},
        options: {
          body: propertyFavStatus ? {} : { property_id: propertyId },
        },
        isHideToast: true,
      })

      setLoading(false)

      if (response.error.value) {
        const message = `Terjadi kesalahan. Gagal ${
          propertyFavStatus ? 'dihapus dari' : 'ditambahkan ke'
        } favorit.`

        commonStore.toggleToast({
          message,
          type: 'error',
        })
      } else {
        const message = `Berhasil ${
          propertyFavStatus ? 'dihapus dari' : 'ditambahkan ke'
        } favorit.`

        commonStore.toggleToast({
          message: message,
          type: 'success',
        })

        await fetchFavorites()
      }

      return response
    }

    commonStore.toggleLoginModal()
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    fetchFavorites,
    isPropertyFaved,
    togglePropertyFavorite,
    resetState,
  }
})
