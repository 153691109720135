<template>
  <component
    :is="getComponentTag()"
    :class="textClasses"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
  export type THeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  export type TParagraph = 'p1' | 'p2' | 'p3' | 'p4'
  export type TTextType = THeading | TParagraph
  export type TTextWeight = 'bold' | 'semibold' | 'medium' | 'regular' | 'light'

  interface ITextProps {
    type?: TTextType
    uppercase?: boolean
    weight?: TTextWeight
  }

  const props = defineProps<ITextProps>()
  const textType = props.type || 'p1'
  const textUppercase = !!props.uppercase
  const textWeight = props.weight || 'regular'

  const getComponentTag = () => {
    return textType.includes('h') ? textType : 'p'
  }
  const textClasses = [
    `text-${textType}`,
    `text-${textWeight}`,
    textUppercase && 'text-uppercase',
  ]
</script>

<style scoped lang="scss">
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .text-h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .text-h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .text-h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .text-h4 {
    font-size: 28px;
    line-height: 36px;
  }

  .text-h5 {
    font-size: 24px;
    line-height: 32px;
  }

  .text-h6 {
    font-size: 20px;
    line-height: 28px;
  }

  .text-p1 {
    font-size: 16px;
    line-height: 24px;
  }

  .text-p2 {
    font-size: 14px;
    line-height: 20px;
  }

  .text-p3 {
    font-size: 12px;
    line-height: 18px;
  }

  .text-p4 {
    font-size: 10px;
    line-height: 14px;
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .text-h1 {
      font-size: 24px;
      line-height: 32px;
    }

    .text-h2 {
      font-size: 20px;
      line-height: 28px;
    }

    .text-h3 {
      font-size: 20px;
      line-height: 28px;
    }

    .text-h4 {
      font-size: 16px;
      line-height: 24px;
    }

    .text-h5 {
      font-size: 16px;
      line-height: 24px;
    }

    .text-h6 {
      font-size: 16px;
      line-height: 24px;
    }

    .text-p1 {
      font-size: 14px;
      line-height: 20px;
    }

    .text-p2 {
      font-size: 14px;
      line-height: 20px;
    }

    .text-p3 {
      font-size: 12px;
      line-height: 18px;
    }

    .text-p4 {
      font-size: 10px;
      line-height: 14px;
    }
  }
</style>
