export const BASE_URL = 'https://api.jualpabrik.com'

type TMethod = 'get' | 'post' | 'put' | 'delete'
export type TPath = [TMethod, string]

// Authentication
export const POST_REGISTER: TPath = ['post', '/users/register']
export const POST_LOGIN: TPath = ['post', '/users/authenticate']
export const POST_VALIDATE_OTP: TPath = ['post', '/users/validate-otp']
export const POST_FORGOT_PASSWORD: TPath = ['post', '/users/forgot-password']
export const POST_RESET_PASSWORD: TPath = ['post', '/users/reset-password']
export const POST_CHECK_USER_EXIST: TPath = ['post', '/users/check']
export const POST_RESEND_OTP: TPath = ['post', '/users/resend-otp-verification']
export const GET_PROFILE: TPath = ['get', '/users/profile']
export const PUT_UPDATE_PROFILE: TPath = ['put', '/users/update-profile']
export const PUT_UPDATE_PASSWORD: TPath = ['put', '/users/update-password']
export const PUT_UPDATE_PHOTO: TPath = ['put', '/users/update-photo']

// Company
export const POST_COMPANY: TPath = ['post', '/companies']
export const PUT_COMPANY: TPath = ['put', '/companies']
export const PUT_COMPANY_LOGO: TPath = ['put', '/companies/logo']

// Master
export const GET_PROVINCES: TPath = ['get', '/master-data/provinces']
export const GET_CITIES: TPath = ['get', '/master-data/regencies']
export const GET_DISTRICTS: TPath = ['get', '/master-data/districts']
export const GET_VILLAGES: TPath = ['get', '/master-data/villages']
export const GET_CONDITIONS: TPath = ['get', '/property-conditions']
export const GET_CERTIFICATES: TPath = ['get', '/certificates']
export const GET_FURNITURE_CONDITIONS: TPath = ['get', '/furniture-condition']
export const GET_FACES: TPath = ['get', '/faces']
export const GET_ELECTRICAL_POWERS: TPath = ['get', '/electrical-powers']
export const GET_FACILITIES: TPath = ['get', '/facilities']

// Property
export const GET_PROPERTIES: TPath = ['get', '/search']
export const GET_PROPERTY_DETAIL: TPath = ['get', '/search/:slug']
export const GET_PROPERTY_CATEGORIES: TPath = ['get', '/categories']
export const GET_MY_PROPERTIES: TPath = ['get', '/properties']
export const POST_PROPERTY: TPath = ['post', '/properties']
export const GET_MY_PROPERTY_DETAIL: TPath = ['get', '/properties/:id']
export const PUT_PROPERTY: TPath = ['put', '/properties/:id']
export const PUT_PROPERTY_BULK: TPath = ['put', '/properties/update-bulk']
export const DELETE_PROPERTY: TPath = ['delete', '/properties/:id']
export const GET_LAST_SEEN_PROPERTIES: TPath = ['get', '/properties/last_seen']
export const PUT_LAST_SEEN_PROPERTIES: TPath = ['put', '/properties/last_seen']

export const POST_PROPERTY_ATTACHMENT: TPath = [
  'post',
  '/properties/:id/attachment',
]
export const PUT_PROPERTY_ATTACHMENT: TPath = [
  'put',
  '/properties/:id/attachment/:id_attachment',
]
export const DELETE_PROPERTY_ATTACHMENT: TPath = [
  'delete',
  '/properties/:id/attachment/:id_attachment',
]

export const POST_PROPERTY_FACILITY: TPath = [
  'post',
  '/properties/:id/facility',
]
export const PUT_PROPERTY_FACILITY: TPath = [
  'put',
  '/properties/:id/facility/:id_facility',
]
export const DELETE_PROPERTY_FACILITY: TPath = [
  'delete',
  '/properties/:id/facility/:id_facility',
]

// Banners
export const GET_BANNERS: TPath = ['get', '/banners']
export const GET_BANNER_DETAIL: TPath = ['get', '/banners/:id']

// Attachment
export const UPLOAD_ATTACHMENT: TPath = ['post', '/attachments']

// Favorite
export const GET_MY_FAVORITES: TPath = ['get', '/favorites']
export const ADD_MY_FAVORITES: TPath = ['post', '/favorites']
export const REMOVE_MY_FAVORITES: TPath = ['delete', '/favorites/:id']

// Article
export const GET_ARTICLES: TPath = ['get', '/articles']
export const GET_ARTICLE_CATEGORIES: TPath = ['get', '/article-categories']
export const GET_ARTICLE_DETAIL: TPath = ['get', '/articles/:id']

// FAQ
export const GET_FAQS: TPath = ['get', '/faqs']
export const GET_FAQ_CATEGORIES: TPath = ['get', '/faq-categories']

// Page CMS
export const GET_PRIVACY_POLICY: TPath = [
  'get',
  '/page_contents/privacy-policy',
]
export const GET_ABOUT_US: TPath = ['get', '/page_contents/about-us']

// External API
export const GET_MAPBOX_REVERSE_GEOCODE: TPath = [
  'get',
  'https://api.mapbox.com/geocoding/v5/mapbox.places',
]
