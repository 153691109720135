import { defineStore } from 'pinia'
import { GET_PROPERTY_CATEGORIES } from '~/constants'

import {
  IListItem,
  IProperty,
  IPropertyCategory,
  IPropertyState,
} from '~/interfaces'

export const usePropertyStore = defineStore('property', () => {
  const getInitialState = (): IPropertyState => ({
    categories: [],
    list: [],
    detail: null,
  })
  const state = ref<IPropertyState>(getInitialState())

  const setPropertyCategories = (value: IPropertyCategory[]) =>
    (state.value.categories = value)
  const setListProperty = (value: IProperty[]) => (state.value.list = value)
  const setDetailProperty = (value: IProperty) => (state.value.detail = value)

  const fetchPropertyCategories = async () => {
    await api<IListItem<IPropertyCategory>>({
      path: GET_PROPERTY_CATEGORIES,
      options: {
        query: {
          limit: 10,
        },
      },
      successCb: (response) =>
        setPropertyCategories(response.data.value.rows || []),
    })
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    fetchPropertyCategories,
    setPropertyCategories,
    setListProperty,
    setDetailProperty,
    resetState,
  }
})
