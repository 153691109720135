import { defineStore } from 'pinia'
import { GET_BANNERS } from '~/constants'

import {
  IListItem,
  IBanner,
  IBannerState,
  EBannerPage,
  EBannerSection,
} from '~/interfaces'

export const useBannerStore = defineStore('banner', () => {
  const getInitialState = (): IBannerState => ({
    homePageBanners: [],
    searchPageBanners: null,
    pdpPageBanners: null,
  })
  const state = ref<IBannerState>(getInitialState())

  const setHomePageBanners = (value: IBanner[]) =>
    (state.value.homePageBanners = value)
  const setSearchPageBanners = (value: Record<EBannerSection, IBanner[]>) =>
    (state.value.searchPageBanners = value)
  const setPdpPageBanners = (value: Record<EBannerSection, IBanner[]>) =>
    (state.value.pdpPageBanners = value)

  const fetchBanner = async () => {
    await api<IListItem<IBanner>>({
      path: GET_BANNERS,
      options: {
        query: {
          limit: 99,
        },
      },
      successCb: (response) => {
        const homePageBanners: IBanner[] = []

        const searchPageBanners: Record<EBannerSection, IBanner[]> = {
          [EBannerSection.SidebarLeft]: [],
          [EBannerSection.SidebarRight]: [],
          [EBannerSection.Top]: [],
          [EBannerSection.Bottom]: [],
          [EBannerSection.Footer]: [],
        }

        const pdpPageBanners: Record<EBannerSection, IBanner[]> = {
          [EBannerSection.SidebarLeft]: [],
          [EBannerSection.SidebarRight]: [],
          [EBannerSection.Top]: [],
          [EBannerSection.Bottom]: [],
          [EBannerSection.Footer]: [],
        }

        response.data.value.rows?.forEach((banner) => {
          if (banner.page === EBannerPage.Home) {
            homePageBanners.push(banner)
          }

          if (banner.page === EBannerPage.Search) {
            searchPageBanners[banner.section].push(banner)
          }

          if (banner.page === EBannerPage.Pdp) {
            pdpPageBanners[banner.section].push(banner)
          }
        })

        setHomePageBanners(homePageBanners)
        setSearchPageBanners(searchPageBanners)
        setPdpPageBanners(pdpPageBanners)
      },
    })
  }

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    fetchBanner,
    resetState,
  }
})
