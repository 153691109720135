<template>
  <button
    class="btn-container"
    :class="btnClasses"
    :disabled="disabled || loading"
  >
    <slot v-if="!loading" />
    <Spinner v-else />
  </button>
</template>

<script setup lang="ts">
  interface IButtonProps {
    disabled?: boolean
    loading?: boolean
    isBorderless?: boolean
    type?: 'primary' | 'secondary'
    textOnly?: boolean
    variant?: 'orange' | 'blue'
  }

  const props = defineProps<IButtonProps>()
  const btnType = props.type || 'primary'
  const btnVariant = props.variant || 'orange'
  const btnClasses = computed(() => {
    const loadingClass = props.loading ? 'btn--loading' : ''

    if (props.textOnly) {
      return [`btn-text text-${btnVariant}`, loadingClass]
    }

    return [
      `${btnType}-${btnVariant}`,
      props.isBorderless ? 'border-plain' : `border-${btnVariant}`,
      loadingClass,
    ]
  })
</script>

<style scoped lang="scss">
  .btn-container {
    padding: 10px 20px;
    outline: none;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    &:disabled {
      cursor: default;
      color: $base700;
      background: $base400;
      border: 1px solid $base400;
      opacity: 1;
    }
  }

  .primary-orange {
    background: $orange500;
    color: $base100;
  }

  .primary-blue {
    background: $blue500;
    color: $base100;
  }

  .secondary-orange {
    background: $base100;
    color: $orange500;
  }

  .secondary-blue {
    background: $base100;
    color: $blue500;
  }

  .border-plain {
    border: 1px solid $base100;

    &:disabled {
      border: none;
    }
  }

  .border-orange {
    border: 1px solid $orange500;
  }

  .border-blue {
    border: 1px solid $blue500;
  }

  .btn-text {
    padding: 0;
    border: none;
    background: none;

    &:disabled {
      border: none;
      background: none;
    }
  }

  .text-orange {
    color: $orange500;
  }

  .text-blue {
    color: $blue500;
  }

  .btn--loading {
    display: flex;
    justify-content: center;
  }
</style>
