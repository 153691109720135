import { IDefaultTimestamp } from './api'

export enum EBannerPage {
  Home = 'homepage',
  Search = 'search',
  Pdp = 'pdp',
}

export enum EBannerSection {
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  Top = 'top',
  Bottom = 'bottom',
  Footer = 'footer',
}

export interface IBanner extends IDefaultTimestamp {
  id: string
  title: string
  image_url: string
  target_url: string
  url_type: string
  description: string
  start_published: string
  end_published: string
  size: string
  page: EBannerPage
  banner_type: 'advertising' | 'system'
  section: EBannerSection
  content: string
  thumbnail: string
  title_promo: string
  position: number
  status: number
}

export interface IBannerState {
  homePageBanners: IBanner[]
  searchPageBanners: Record<EBannerSection, IBanner[]> | null
  pdpPageBanners: Record<EBannerSection, IBanner[]> | null
}
