import { IOption } from './common'

export enum EUserType {
  Default = 1,
  Agent = 2,
  Admin = 3,
}

export enum ESellerFormModalContent {
  SellerTypeOptions = 'sellerTypeOptions',
  SellerCompanyAgentForm = 'sellerCompanyAgentForm',
  SellerIndependentAgentForm = 'sellerIndependentAgentForm',
  SellerDeveloperForm = 'sellerDeveloperForm',
  SellerPropertyOwnerForm = 'sellerPropertyOwnerForm',
}

export enum EAgentType {
  Owner = 1,
  IndependentAgent = 2,
  CompanyAgent = 3,
  DeveloperAgent = 4,
}

export interface IPersonalForm {
  photo: Blob | string
  name: string
  email: string
  phone_number: string
}

export interface ISellerCompanyAgentForm {
  email: string
  company: string
  province: IOption
  city: IOption
  district: IOption
  address: string
  waNumber?: string
  companyPhoto: string | { image: Blob; name: string }
}

export interface ISellerIndependentAgentForm {
  email: string
  province: IOption
  city: IOption
  district: IOption
  address: string
  companyPhoto: string | { image: Blob; name: string }
}

export interface ISellerPropertyOwnerForm {
  email: string
  province: IOption
  city: IOption
  district: IOption
  address: string
}

export interface ISellerDeveloperForm {
  email: string
  company: string
  project: string
  province: IOption
  city: IOption
  district: IOption
  address: string
  waNumber?: string
  companyPhoto: string | { image: Blob; name: string }
}

export interface IProfileState {
  isShowSellerModal: boolean
}
