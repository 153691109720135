<template>
  <Modal
    v-if="commonState.resetPasswordModalState.isShow"
    class="reset-password-modal"
    @close-modal="onClickCloseModal"
  >
    <Button
      v-if="currentStep === 'emailVerification'"
      text-only
      class="back-btn"
      @click="onClickBack"
    >
      <ArrowIcon
        width="20"
        height="20"
      />
    </Button>
    <div class="reset-password-modal-header">
      <component
        :is="displayStep[currentStep].icon"
        width="160"
        height="160"
      />
      <Text
        type="p1"
        weight="bold"
      >
        {{ displayStep[currentStep].title }}
      </Text>
      <Text
        type="p2"
        class="reset-password-caption"
      >
        {{ displayStep[currentStep].caption }}
      </Text>
    </div>
    <InputText
      v-if="currentStep === 'forgetPassword'"
      id="emailOrHandphone"
      label="Email atau No. Handphone"
      class="reset-password-input"
      :value="values.email"
      :error-message="emailErrorMessage"
      @input="(value) => setFieldValue('email', value)"
    />
    <InputOtp
      v-else-if="currentStep === 'emailVerification'"
      :disabled="isLoading"
      :value="values.otp"
      :error-message="otpErrorMessage"
      @change="(value) => setFieldValue('otp', value)"
      @complete="onOtpComplete"
    />
    <div v-else>
      <InputTextPassword
        label="Kata Sandi"
        :value="values.password"
        :error-message="passwordErrorMessage"
        class="reset-password-input mb-4"
        @input="(value) => setFieldValue('password', value)"
      />
      <InputTextPassword
        id="passwordConfirmation"
        label="Konfirmasi Kata Sandi"
        :value="values.passwordConfirmation"
        :error-message="passwordConfirmationErrorMessage"
        class="reset-password-input"
        is-show-password-criteria
        @input="(value) => setFieldValue('passwordConfirmation', value)"
      />
    </div>

    <Button
      v-if="currentStep === 'forgetPassword'"
      :loading="isLoading"
      @click="onClickSend"
    >
      Kirim
    </Button>
    <Button
      v-else-if="currentStep === 'emailVerification'"
      text-only
      :loading="isLoading"
      @click="resendOtp"
    >
      {{ sendOtpText }}
    </Button>
    <Button
      v-else
      :loading="isLoading"
      @click="onSaveClick"
    >
      Simpan
    </Button>
  </Modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { object, string } from 'yup'

  import ArrowIcon from '~/assets/icons/arrow.svg'
  import ResetPasswordIcon from '~/assets/icons/reset-password.svg'
  import EmailVerificationIcon from '~/assets/icons/email-verification.svg'

  import { useCommonStore } from '~/store'
  import { checkIsEmail, checkIsPhoneNumber } from '~/utils'
  import {
    POST_FORGOT_PASSWORD,
    POST_RESET_PASSWORD,
    POST_RESEND_OTP,
    POST_VALIDATE_OTP,
  } from '~/constants'
  import { EOtpType, EStatusCode } from '~/interfaces'

  type TStep = 'forgetPassword' | 'emailVerification' | 'resetPassword'

  interface IStepDetail {
    icon: string
    title: string
    caption: string
  }

  interface IResetPasswordForm {
    email: string
    otp: string
    password: string
    passwordConfirmation: string
    token: string
  }

  const displayStep: Record<TStep, IStepDetail> = {
    forgetPassword: {
      icon: ResetPasswordIcon,
      title: 'Lupa Kata Sandi',
      caption:
        'Silakan masukkan email atau No. Handphone Anda untuk mengatur ulang kata sandi',
    },
    emailVerification: {
      icon: EmailVerificationIcon,
      title: 'Verifikasi Email',
      caption:
        'Kami telah mengirimkan kode verifikasi ke email Anda. Masukkan kode tersebut di sini untuk melanjutkan',
    },
    resetPassword: {
      icon: ResetPasswordIcon,
      title: 'Ubah Kata Sandi',
      caption: 'Silakan masukkan kata sandi yang baru',
    },
  }

  const currentStep = ref<TStep>('forgetPassword')
  const isLoading = ref(false)
  const currentTime = ref(0)

  const { values, resetForm, setFieldValue, validate } =
    useForm<IResetPasswordForm>({
      validationSchema: object({
        email: string()
          .required()
          .label('Email atau No. Handphone')
          .test({
            name: 'emailAndPhone',
            test(value, ctx) {
              if (!checkIsEmail(value) && !checkIsPhoneNumber(value)) {
                return ctx.createError({
                  message: 'Email atau No. Handphone tidak valid',
                })
              }

              return true
            },
          }),
        otp: string().required().label('Kode verifikasi'),
        password: string().required().label('Kata Sandi'),
        passwordConfirmation: string()
          .required()
          .label('Konfirmasi Kata Sandi'),
        token: string(),
      }),
      initialValues: {
        email: '',
        otp: '',
        password: '',
        passwordConfirmation: '',
        token: '',
      },
    })

  const { errorMessage: emailErrorMessage, validate: validateEmail } =
    useField('email')
  const {
    errorMessage: otpErrorMessage,
    setErrors: setOtpError,
    setTouched: setOtpTouched,
  } = useField('otp')
  const { errorMessage: passwordErrorMessage, validate: validatePassword } =
    useField('password')
  const {
    errorMessage: passwordConfirmationErrorMessage,
    validate: validatePasswordConfirmation,
  } = useField('passwordConfirmation')

  const commonStore = useCommonStore()
  const { state: commonState } = storeToRefs(commonStore)

  const sendOtpText = computed(() => {
    if (currentTime.value) {
      return formatMilliseconds(currentTime.value)
    }

    return 'Kirim Ulang'
  })

  useIntervalFn(() => {
    if (commonState.value.resetPasswordModalState.isShow) {
      const otpTime = getLatestOtpTime()

      if (otpTime) {
        const timeDiff = new Date(otpTime).getTime() - new Date().getTime()

        if (timeDiff > 0) {
          currentTime.value = timeDiff
        } else {
          currentTime.value = 0
        }
      }
    }
  }, 1000)

  const onClickBack = () => {
    setFieldValue('otp', '')
    currentStep.value = 'forgetPassword'
  }

  const onClickSend = async () => {
    isLoading.value = true

    const { errors } = await validateEmail()

    if (!errors.length) {
      const body = { email: values.email }

      const { error } = await api({
        isHideToast: true,
        path: POST_FORGOT_PASSWORD,
        options: { body },
      })

      if (!error.value) {
        currentStep.value = 'emailVerification'
      } else {
        if (error.value.statusCode === EStatusCode.NotFound) {
          commonStore.toggleToast({
            type: 'error',
            message: 'Email atau No. Handphone tidak ditemukan',
          })
        } else {
          commonStore.toggleToast({
            type: 'error',
            message: 'Terjadi kesalahan',
          })
        }
      }
    }

    isLoading.value = false
  }

  const resendOtp = async () => {
    if (!currentTime.value) {
      const body = { email: values.email }
      const { error } = await api({
        path: POST_RESEND_OTP,
        options: { body },
      })

      if (!error.value) {
        setLatestOtpTime()

        commonStore.toggleToast({
          type: 'success',
          message: 'Kode OTP telah dikirim',
        })
      }
    }
  }

  const onOtpComplete = async (value: string) => {
    isLoading.value = true

    const body = {
      otp: value,
      email: values.email,
      otp_type: EOtpType.FORGOT_PASSWORD,
    }

    const { data, error } = await api<{ data: { token: string } }>({
      path: POST_VALIDATE_OTP,
      options: { body },
    })

    if (!error.value) {
      setFieldValue('token', data.value.data.token)
      commonStore.toggleToast({
        type: 'success',
        message: 'Kode OTP benar. Mohon masukkan password baru.',
      })
      currentStep.value = 'resetPassword'
    }

    isLoading.value = false
  }

  const resetState = () => {
    currentStep.value = 'forgetPassword'
    resetForm()
  }

  const onClickCloseModal = () => {
    resetState()
    commonStore.toggleResetPasswordModal('')
  }

  const onSaveClick = async () => {
    isLoading.value = true

    const { errors: passwordErrors } = await validatePassword()
    const { errors: passwordConfirmationErrors } =
      await validatePasswordConfirmation()

    if (!passwordErrors.length && !passwordConfirmationErrors.length) {
      const body = {
        new_password: values.password,
        token: values.token,
      }

      const { error } = await api({
        path: POST_RESET_PASSWORD,
        options: { body },
      })

      if (!error.value) {
        commonStore.toggleToast({
          type: 'success',
          message: 'Password baru berhasil disimpan.',
        })

        onClickCloseModal()
      }
    }

    isLoading.value = false
  }

  watch(
    () => ({ prevEmail: commonState.value.resetPasswordModalState.email }),
    ({ prevEmail }) => {
      if (prevEmail) setFieldValue('email', prevEmail)
    },
  )
</script>

<style scoped lang="scss">
  ::v-deep(.reset-password-modal) {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .back-btn {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .reset-password-modal-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      svg {
        margin: auto;
      }

      .reset-password-caption {
        color: $base800;
      }
    }

    .reset-password-input {
      input {
        width: 100%;
      }
    }

    @media screen and (max-width: ($mobileLarge + 'px')) {
      width: auto;
    }
  }
</style>
