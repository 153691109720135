<template>
  <Modal
    v-if="commonState.isShowLoginModal"
    class="login-modal"
    @close-modal="onClickCloseModal"
  >
    <Button
      v-if="showPassword"
      text-only
      class="back-btn"
      @click="showPassword = false"
    >
      <ArrowIcon
        width="20"
        height="20"
      />
    </Button>
    <div class="login-modal-header">
      <LoginIcon
        width="160"
        height="160"
      />
      <Text
        type="p1"
        weight="bold"
      >
        Silakan masuk atau daftar
      </Text>
      <Text
        v-if="showPassword"
        type="p2"
        class="login-caption"
      >
        Silakan masukkan password untuk masuk ke akun Anda
      </Text>
    </div>

    <div class="login-input-container">
      <InputText
        id="emailOrHandphone"
        label="Email atau No. Handphone"
        :class="showPassword && 'mb-4'"
        :value="values.email"
        :error-message="emailErrorMessage"
        @input="(value) => setFieldValue('email', value)"
      />

      <InputTextPassword
        v-if="showPassword"
        label="Kata Sandi"
        :value="values.password"
        :error-message="passwordErrorMessage"
        @input="(value) => setFieldValue('password', value)"
      />

      <Button
        v-if="showPassword"
        text-only
        class="mt-2 forget-password-text"
        @click="onClickForgetPassword"
      >
        Lupa Kata Sandi?
      </Button>
    </div>

    <Button
      :loading="isLoading"
      @click="showPassword ? onClickLogin() : onClickContinue()"
    >
      {{ showPassword ? 'Masuk' : 'Lanjutkan' }}
    </Button>

    <!-- @TODO: show when login using Google is provided -->
    <!-- <div
      v-if="!showPassword"
      class="login-modal-divider"
    >
      <hr />
      <Text type="p3"> Atau </Text>
      <hr />
    </div>

    <Button
      v-if="!showPassword"
      type="secondary"
      variant="blue"
      class="google-btn"
    >
      <GoogleIcon
        width="14"
        height="14"
      />
      <span>Lanjut dengan Google</span>
    </Button> -->
  </Modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { object, string } from 'yup'

  import ArrowIcon from '~/assets/icons/arrow.svg'
  import GoogleIcon from '~/assets/icons/google.svg'
  import LoginIcon from '~/assets/icons/login.svg'

  import { POST_CHECK_USER_EXIST, POST_LOGIN } from '~/constants'
  import { useCommonStore } from '~/store'
  import { checkIsEmail, checkIsPhoneNumber } from '~/utils'
  import {
    EStatusCode,
    ILoginForm,
    ILoginResponse,
    IResponse,
  } from '~/interfaces'

  const showPassword = ref(false)
  const isLoading = ref(false)

  const { values, resetForm, setFieldValue, validate } = useForm<ILoginForm>({
    validationSchema: object({
      email: string()
        .required()
        .label('Email atau No. Handphone')
        .test({
          name: 'emailAndPhone',
          test(value, ctx) {
            if (!checkIsEmail(value) && !checkIsPhoneNumber(value)) {
              return ctx.createError({
                message: 'Email atau No. Handphone tidak valid',
              })
            }

            return true
          },
        }),
      password: string().required().label('Kata Sandi'),
    }),
    initialValues: {
      email: '',
      password: '',
    },
  })

  const { errorMessage: emailErrorMessage, validate: validateEmail } =
    useField('email')
  const { errorMessage: passwordErrorMessage } = useField('password')

  const commonStore = useCommonStore()
  const { state: commonState } = storeToRefs(commonStore)

  const resetState = () => {
    showPassword.value = false
    resetForm()
  }

  const onClickContinue = async () => {
    const { errors } = await validateEmail()

    if (!errors.length) {
      const { error } = await api({
        isHideToast: true,
        path: POST_CHECK_USER_EXIST,
        options: {
          body: {
            type: checkIsEmail(values.email) ? 'email' : 'phone-number',
            value: values.email,
          },
        },
      })

      if (!error.value?.statusCode) {
        showPassword.value = true
      } else {
        if (error.value.statusCode === EStatusCode.NotFound) {
          commonStore.toggleLoginModal()
          commonStore.toggleRegisterModal(values.email)
        } else {
          commonStore.toggleToast({
            type: 'error',
            message: 'Terjadi kesalahan',
          })
        }
      }
    }
  }

  const onClickLogin = async () => {
    isLoading.value = true

    const { valid } = await validate()

    if (valid) {
      const body = { ...values }

      const { data, error } = await api<IResponse<ILoginResponse>>({
        path: POST_LOGIN,
        options: { body },
      })

      if (!error.value) {
        setToken(data.value.data.access_token)

        commonStore.toggleLoginModal()
        window.location.replace('/me/profile')

        commonStore.toggleToast({
          type: 'success',
          message: 'Selamat datang kembali!',
        })
      }
    }

    isLoading.value = false
  }

  const onClickCloseModal = () => {
    resetState()
    commonStore.toggleLoginModal()
  }

  const onClickForgetPassword = () => {
    const email = values.email

    onClickCloseModal()
    commonStore.toggleResetPasswordModal(email)
  }
</script>

<style scoped lang="scss">
  ::v-deep(.login-modal) {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .back-btn {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .login-modal-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      svg {
        margin: auto;
      }

      .login-caption {
        color: $base800;
      }
    }

    .login-input-container {
      display: flex;
      flex-direction: column;
    }

    .forget-password-text {
      text-align: right;
      width: fit-content;
      align-self: flex-end;
    }

    .login-modal-divider {
      display: flex;
      gap: 8px;

      hr {
        flex-grow: 1;
        height: 1px;
        background-color: $base400;
        border: none;
      }
    }

    .google-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    @media screen and (max-width: ($mobileLarge + 'px')) {
      width: auto;
    }
  }
</style>
