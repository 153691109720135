import { default as indexu9qtuh0b8VMeta } from "/home/komit/property/workspace/web-property/src/pages/about-us/index.vue?macro=true";
import { default as _91id_93reidaSDd0fMeta } from "/home/komit/property/workspace/web-property/src/pages/article/[id].vue?macro=true";
import { default as indexaGABS7T5qVMeta } from "/home/komit/property/workspace/web-property/src/pages/article/index.vue?macro=true";
import { default as indexh6QepjlMQeMeta } from "/home/komit/property/workspace/web-property/src/pages/faq/index.vue?macro=true";
import { default as indexi8SvKa4XOsMeta } from "/home/komit/property/workspace/web-property/src/pages/index.vue?macro=true";
import { default as add_45adseWu6aQFyyLMeta } from "/home/komit/property/workspace/web-property/src/pages/me/add-ads.vue?macro=true";
import { default as _91id_93OCSeBZuoa1Meta } from "/home/komit/property/workspace/web-property/src/pages/me/ads/[id].vue?macro=true";
import { default as indexPFPFNGBfJmMeta } from "/home/komit/property/workspace/web-property/src/pages/me/ads/index.vue?macro=true";
import { default as favoritecvJcEdCg63Meta } from "/home/komit/property/workspace/web-property/src/pages/me/favorite.vue?macro=true";
import { default as historyH7xOhMOA5WMeta } from "/home/komit/property/workspace/web-property/src/pages/me/history.vue?macro=true";
import { default as profiley7xKv3s1LCMeta } from "/home/komit/property/workspace/web-property/src/pages/me/profile.vue?macro=true";
import { default as indexfEGfB9cJuhMeta } from "/home/komit/property/workspace/web-property/src/pages/privacy-policy/index.vue?macro=true";
import { default as _91id_93dKUxtxEbaxMeta } from "/home/komit/property/workspace/web-property/src/pages/promo/[id].vue?macro=true";
import { default as _91slug_93sar3Ic0j5TMeta } from "/home/komit/property/workspace/web-property/src/pages/property/[slug].vue?macro=true";
import { default as searchs0LNAQAZpAMeta } from "/home/komit/property/workspace/web-property/src/pages/search.vue?macro=true";
export default [
  {
    name: indexu9qtuh0b8VMeta?.name ?? "about-us",
    path: indexu9qtuh0b8VMeta?.path ?? "/about-us",
    meta: indexu9qtuh0b8VMeta || {},
    alias: indexu9qtuh0b8VMeta?.alias || [],
    redirect: indexu9qtuh0b8VMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93reidaSDd0fMeta?.name ?? "article-id",
    path: _91id_93reidaSDd0fMeta?.path ?? "/article/:id()",
    meta: _91id_93reidaSDd0fMeta || {},
    alias: _91id_93reidaSDd0fMeta?.alias || [],
    redirect: _91id_93reidaSDd0fMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/article/[id].vue").then(m => m.default || m)
  },
  {
    name: indexaGABS7T5qVMeta?.name ?? "article",
    path: indexaGABS7T5qVMeta?.path ?? "/article",
    meta: indexaGABS7T5qVMeta || {},
    alias: indexaGABS7T5qVMeta?.alias || [],
    redirect: indexaGABS7T5qVMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: indexh6QepjlMQeMeta?.name ?? "faq",
    path: indexh6QepjlMQeMeta?.path ?? "/faq",
    meta: indexh6QepjlMQeMeta || {},
    alias: indexh6QepjlMQeMeta?.alias || [],
    redirect: indexh6QepjlMQeMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8SvKa4XOsMeta?.name ?? "index",
    path: indexi8SvKa4XOsMeta?.path ?? "/",
    meta: indexi8SvKa4XOsMeta || {},
    alias: indexi8SvKa4XOsMeta?.alias || [],
    redirect: indexi8SvKa4XOsMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: add_45adseWu6aQFyyLMeta?.name ?? "me-add-ads",
    path: add_45adseWu6aQFyyLMeta?.path ?? "/me/add-ads",
    meta: add_45adseWu6aQFyyLMeta || {},
    alias: add_45adseWu6aQFyyLMeta?.alias || [],
    redirect: add_45adseWu6aQFyyLMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/add-ads.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OCSeBZuoa1Meta?.name ?? "me-ads-id",
    path: _91id_93OCSeBZuoa1Meta?.path ?? "/me/ads/:id()",
    meta: _91id_93OCSeBZuoa1Meta || {},
    alias: _91id_93OCSeBZuoa1Meta?.alias || [],
    redirect: _91id_93OCSeBZuoa1Meta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/ads/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPFPFNGBfJmMeta?.name ?? "me-ads",
    path: indexPFPFNGBfJmMeta?.path ?? "/me/ads",
    meta: indexPFPFNGBfJmMeta || {},
    alias: indexPFPFNGBfJmMeta?.alias || [],
    redirect: indexPFPFNGBfJmMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/ads/index.vue").then(m => m.default || m)
  },
  {
    name: favoritecvJcEdCg63Meta?.name ?? "me-favorite",
    path: favoritecvJcEdCg63Meta?.path ?? "/me/favorite",
    meta: favoritecvJcEdCg63Meta || {},
    alias: favoritecvJcEdCg63Meta?.alias || [],
    redirect: favoritecvJcEdCg63Meta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/favorite.vue").then(m => m.default || m)
  },
  {
    name: historyH7xOhMOA5WMeta?.name ?? "me-history",
    path: historyH7xOhMOA5WMeta?.path ?? "/me/history",
    meta: historyH7xOhMOA5WMeta || {},
    alias: historyH7xOhMOA5WMeta?.alias || [],
    redirect: historyH7xOhMOA5WMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/history.vue").then(m => m.default || m)
  },
  {
    name: profiley7xKv3s1LCMeta?.name ?? "me-profile",
    path: profiley7xKv3s1LCMeta?.path ?? "/me/profile",
    meta: profiley7xKv3s1LCMeta || {},
    alias: profiley7xKv3s1LCMeta?.alias || [],
    redirect: profiley7xKv3s1LCMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/me/profile.vue").then(m => m.default || m)
  },
  {
    name: indexfEGfB9cJuhMeta?.name ?? "privacy-policy",
    path: indexfEGfB9cJuhMeta?.path ?? "/privacy-policy",
    meta: indexfEGfB9cJuhMeta || {},
    alias: indexfEGfB9cJuhMeta?.alias || [],
    redirect: indexfEGfB9cJuhMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dKUxtxEbaxMeta?.name ?? "promo-id",
    path: _91id_93dKUxtxEbaxMeta?.path ?? "/promo/:id()",
    meta: _91id_93dKUxtxEbaxMeta || {},
    alias: _91id_93dKUxtxEbaxMeta?.alias || [],
    redirect: _91id_93dKUxtxEbaxMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/promo/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sar3Ic0j5TMeta?.name ?? "property-slug",
    path: _91slug_93sar3Ic0j5TMeta?.path ?? "/property/:slug()",
    meta: _91slug_93sar3Ic0j5TMeta || {},
    alias: _91slug_93sar3Ic0j5TMeta?.alias || [],
    redirect: _91slug_93sar3Ic0j5TMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/property/[slug].vue").then(m => m.default || m)
  },
  {
    name: searchs0LNAQAZpAMeta?.name ?? "search",
    path: searchs0LNAQAZpAMeta?.path ?? "/search",
    meta: searchs0LNAQAZpAMeta || {},
    alias: searchs0LNAQAZpAMeta?.alias || [],
    redirect: searchs0LNAQAZpAMeta?.redirect || undefined,
    component: () => import("/home/komit/property/workspace/web-property/src/pages/search.vue").then(m => m.default || m)
  }
]