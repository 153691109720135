import { defineStore } from 'pinia'

import { ISearchState } from '~/interfaces'

export const useSearchStore = defineStore('search', () => {
  const getInitialState = (): ISearchState => ({
    isLoading: false,
  })
  const state = ref<ISearchState>(getInitialState())

  const toggleSearchLoading = () =>
    (state.value.isLoading = !state.value.isLoading)

  const resetState = () => (state.value = getInitialState())

  return {
    state,
    toggleSearchLoading,
    resetState,
  }
})
