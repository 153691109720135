<template>
  <div>
    <div class="input-text-container">
      <label
        v-if="$slots.prependLabel"
        :for="id"
        class="input-text-label"
        :class="[
          isPrependLabelWithBg && 'input-text-label--custom-bg',
          errorMessage && 'input-text-label--error',
        ]"
      >
        <Text
          type="p2"
          class="prepend-label"
        >
          <slot name="prependLabel"></slot>
        </Text>
      </label>
      <div
        class="input-container"
        :class="[
          errorMessage && 'input-container--error',
          disabled && 'input-container--disabled',
          isPrependLabelWithBg && 'input-container--no-border-left',
        ]"
      >
        <span
          v-if="label"
          v-show="isShowInputLabel || disabled || disabledInputOnly"
          class="input-label"
          :class="$slots.leftSideAction && 'input-label--left-action'"
        >
          {{ label }}
        </span>
        <div
          v-if="$slots.leftSideAction"
          class="ml-4"
        >
          <slot name="leftSideAction" />
        </div>
        <input
          :id="id"
          :placeholder="isShowInputLabel ? '' : inputPlaceholder"
          :type="type || 'text'"
          :value="value"
          :disabled="disabled || disabledInputOnly"
          :class="[
            (isUseInputFocusClass || disabled || disabledInputOnly) &&
              'input--focus',
            disabledInputOnly && 'input--disabled-input-only',
          ]"
          @focusin="onFocusIn"
          @focusout="onFocusOut"
          @input="onInput"
        />
        <div
          v-if="$slots.rightSideAction"
          class="mr-4"
        >
          <slot name="rightSideAction" />
        </div>
      </div>
    </div>

    <Text
      v-if="errorMessage"
      type="p3"
      class="mt-1 text--error"
    >
      {{ errorMessage }}
    </Text>
    <div
      v-else
      :class="$slots.helperText && 'mt-1'"
    >
      <slot name="helperText" />
    </div>
  </div>
</template>

<script setup lang="ts">
  interface IInputTextProps {
    id?: string
    label?: string
    errorMessage?: string
    placeholder?: string
    type?: string
    value: string
    disabled?: boolean
    disabledInputOnly?: boolean
    digitOnly?: boolean
    isPrependLabelWithBg?: boolean
    isUseDebounce?: boolean
  }

  interface IInputTextEmits {
    (e: 'click'): void
    (e: 'input', value: string): void
  }

  const props = defineProps<IInputTextProps>()
  const emits = defineEmits<IInputTextEmits>()

  const isShowInputLabel = ref(!!props.value || false)
  const isUseInputFocusClass = computed(
    () => isShowInputLabel.value && !!props.label,
  )

  const inputPlaceholder = props.placeholder || props.label

  const debouncedEmit = useDebounceFn(
    (e: Event) => emits('input', (e.target as HTMLInputElement).value),
    props.isUseDebounce ? 1000 : 0,
  )

  const onInput = (e: Event) => debouncedEmit(e)

  const onFocusIn = () => {
    isShowInputLabel.value = true
  }

  const onFocusOut = () => {
    isShowInputLabel.value = !!props.value
  }
</script>

<style scoped lang="scss">
  .input-text-container {
    display: flex;
    align-items: center;

    .input-text-label {
      color: $base900;
      border: 1px solid $base500;
      border-right: none;
      padding: 16px 14px;
      background: $base100;
    }

    .input-text-label--error {
      border: 1px solid $red;
      border-right: none;
    }

    .input-text-label--custom-bg {
      background: $base200;
    }

    .prepend-label {
      line-height: 18px;
      position: relative;
    }

    .input-container {
      width: 100%;
      position: relative;
      border: 1px solid $base500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $base100;
    }

    .input-container--no-border-left {
      border-left: none;
    }

    .input-container--disabled {
      border: 1px solid $base400;
      background: $base400;
    }

    .input-container--error {
      border: 1px solid $red;

      .input-label {
        color: $red;
      }

      input::placeholder {
        color: $red;
      }
    }

    .input-label {
      font-size: 10px;
      color: $base700;
      position: absolute;
      top: 7px;
      left: 16px;
    }

    .input-label--left-action {
      left: 48px;
    }

    input {
      padding: 16px;
      border: none;
      outline: none;
      font-size: 14px;
      width: 100%;

      &::placeholder {
        color: $base700;
        font-size: 14px;
      }

      &:disabled {
        background: $base400;
        color: $base900;
      }
    }

    .input--disabled-input-only {
      &:disabled {
        background: none;
        color: $black;
      }
    }

    .input--focus {
      margin-top: 8px;
      padding: 16px 16px 8px;
    }
  }

  .text--error {
    color: $red;
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .input-text-container {
      .prepend-label {
        line-height: 18px;
      }
    }
  }
</style>
